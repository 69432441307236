import { BEAT_TYPE, EVENT_CONST_TYPES } from 'constant/EventConst';

import {
  IEctopicResponseResult,
  IBeatEvent,
  BeatTypeGroup,
  InRangeFinder,
  RangeOrUndefined,
  BeatsNBeatEventsList,
} from '@type/beatNEctopic/process';
import { BeatsApiResponse } from '@dtoType/patchEcgs/beats/filter-waveformIndexRange';
import { WaveformIndex } from '@type/ecgEventType/eventUnit';

import {
  compareBeatEvents,
  getEctopicType,
  getEdgeMarkerValues,
  getKeyWaveformIndex,
  getThirtySecIntervalList,
  hasOverlappedRange,
  isSomeBeatIncludesOtherList,
  isRegular30sWaveformIndexRangeRange,
} from './common';

import { getEventInfo } from '../EventConstUtil';

const BEAT_TYPE_NORMAL = BEAT_TYPE.NORMAL;
const BEAT_TYPE_APC = BEAT_TYPE.APC;
const THIRTY_SEC_WAVEFORM_LENGTH: number = 7500;
const INITIAL_ONSET_MARKER_WAVEFORM_INDEX: number = -1;
const INITIAL_TERMINATION_MARKER_WAVEFORM_INDEX: number =
  Number.MAX_SAFE_INTEGER;

/** Beats API 응답 데이터를 참조하여 BeatsNBeatEvents(beatsNEctopic) 목록 반환 */
export function getBeatsNBeatEventsList(
  onsetWaveformIndex: WaveformIndex,
  terminationWaveformIndex: WaveformIndex,
  beats: BeatsApiResponse,
  onsetBeatEventRealData: IEctopicResponseResult | undefined,
  getOverLappedEventList: InRangeFinder
): BeatsNBeatEventsList {
  if (
    !isRegular30sWaveformIndexRangeRange(
      onsetWaveformIndex,
      terminationWaveformIndex
    )
  ) {
    throw new Error(
      'getBeatsNBeatEventsList error: 잘못된 onsetWaveformIndex 또는 terminationWaveformIndex'
    );
  }

  let beatsNBeatEventsList = getInitialStructure(
    onsetWaveformIndex,
    terminationWaveformIndex
  );

  setBeats(beatsNBeatEventsList, beats);
  const totalBeatEventList = getBeatEventList(beats, getOverLappedEventList);
  setBeatEventList(
    beatsNBeatEventsList,
    totalBeatEventList,
    onsetBeatEventRealData
  );

  return beatsNBeatEventsList;
}

// :: Initialize
/** 정규 30초 구간의 Beat 정보와 Beat Event 목록을 위한 초기화된 자료구조를 반환 */
function getInitialStructure(
  onset: WaveformIndex,
  termination: WaveformIndex
): BeatsNBeatEventsList {
  const createAt = new Date().getTime();

  const result = getThirtySecIntervalList(onset, termination).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: {
        createAt: createAt,
        onsetWaveformIndex: cur,
        terminationWaveformIndex: cur + THIRTY_SEC_WAVEFORM_LENGTH,
        beats: {
          waveformIndex: [],
          beatType: [],
          hr: [],
        },
        noises: [],
        ectopics: [],
        beatEvents: [],
      },
    }),
    {}
  );

  return result;
}

// :: Setting Information
/**
 * # 각 구간 별 BeatsNBeatEvents에 해당하는 Beats 데이터 할당
 *  * param beatsNBeatEventsList객체의 beats property의 3개 property에(waveformIndex, beatType, hr) rawBeats의 값으로 할당합니다.
 * @param beatsNBeatEventsList
 * @param rawBeats
 */
function setBeats(
  beatsNBeatEventsList: BeatsNBeatEventsList,
  rawBeats: BeatsApiResponse
) {
  for (let beatIndex = 0; beatIndex < rawBeats.beatType.length; beatIndex++) {
    const thirtyKey = getKeyWaveformIndex(rawBeats.waveformIndex[beatIndex]);
    let curBeatsNBeatEvents = beatsNBeatEventsList[thirtyKey];
    if (!curBeatsNBeatEvents) continue;

    curBeatsNBeatEvents.beats.waveformIndex.push(
      rawBeats.waveformIndex[beatIndex]
    );
    curBeatsNBeatEvents.beats.beatType.push(rawBeats.beatType[beatIndex]);
    curBeatsNBeatEvents.beats.hr.push(rawBeats.hr[beatIndex]);
  }
}
/** beatsNBeatEventsList의 각 정규 30초 구간의 "beatEvents"속성에 Beat Event목록 정보 할당 */
function setBeatEventList(
  beatsNBeatEventsList: BeatsNBeatEventsList,
  totalBeatEventList: Array<IBeatEvent>,
  onsetBeatEventRealData: IEctopicResponseResult | undefined
) {
  if (onsetBeatEventRealData) {
    const {
      waveformIndex: realWaveformIndexList,
      ectopicType,
      beatType,
    } = onsetBeatEventRealData;
    for (let i = 0; i < totalBeatEventList.length; i++) {
      if (
        !realWaveformIndexList.includes(totalBeatEventList[i].onsetRPeakIndex)
      ) {
        continue;
      }
      totalBeatEventList[i].onsetRPeakIndex = realWaveformIndexList[0];
      totalBeatEventList[i].waveformIndex = realWaveformIndexList;
      totalBeatEventList[i].ectopicType = ectopicType;
      totalBeatEventList[i].type =
        getEventInfo({
          beatType,
          ectopicType,
        }).findOne()?.type ?? EVENT_CONST_TYPES.NOISE;
      totalBeatEventList[i].onsetWaveformIndex =
        INITIAL_ONSET_MARKER_WAVEFORM_INDEX; // 임의의 초기값!
      totalBeatEventList[i].hasOnsetMarker = false;
      break;
    }
  }

  Object.keys(beatsNBeatEventsList).forEach((strKey) => {
    const curOnsetWaveformIndex = Number.parseInt(strKey);
    let curBeatsNBeatEvents = beatsNBeatEventsList[curOnsetWaveformIndex];
    curBeatsNBeatEvents.beatEvents = totalBeatEventList
      .filter((beatEvent) => {
        if (!(beatEvent.hasOnsetMarker && beatEvent.hasTerminationMarker)) {
          return isSomeBeatIncludesOtherList(
            beatEvent.waveformIndex,
            curBeatsNBeatEvents.beats.waveformIndex
          );
        }

        return hasOverlappedRange(
          {
            onsetWaveformIndex:
              beatEvent.onsetWaveformIndex ?? Number.MIN_SAFE_INTEGER,
            terminationWaveformIndex:
              beatEvent.terminationWaveformIndex ?? Number.MAX_SAFE_INTEGER,
          },
          curBeatsNBeatEvents
        );
      })
      .sort(compareBeatEvents);
    const { noises, ectopics } = curBeatsNBeatEvents.beatEvents.reduce(
      (acc, cur) => {
        if (cur.beatType === BEAT_TYPE.NOISE) acc.noises.push(cur);
        else acc.ectopics.push(cur);
        return acc;
      },
      { noises: Array<IBeatEvent>(), ectopics: Array<IBeatEvent>() }
    );
    curBeatsNBeatEvents.noises = noises;
    curBeatsNBeatEvents.ectopics = ectopics;
  });
}

// Getting Information
/** Beats 를 통해 Beat Event 목록을 반환 */
function getBeatEventList(
  rawBeats: BeatsApiResponse,
  getOverLappedEventList: InRangeFinder
): Array<IBeatEvent> {
  const groupList = getBeatTypeGroupList(rawBeats);

  const step1 = groupList.filter((group) => group.waveformIndexList.length > 0);
  // group 구간안에 Lead-Off가 있는지 확인하여 group 분리
  const step2 = step1.reduce((acc, group) => {
    const leadOffList = getOverLappedEventList(
      {
        onsetWaveformIndex:
          group.waveformIndexList.at(0) ?? Number.MIN_SAFE_INTEGER,
        terminationWaveformIndex:
          group.waveformIndexList.at(-1) ?? Number.MAX_SAFE_INTEGER,
      },
      false
    );

    if (leadOffList.length > 0) {
      let subList = Array<BeatTypeGroup>();
      let curSubGroup: BeatTypeGroup | null = null;
      for (
        let beatIndex = 0;
        beatIndex < group.waveformIndexList.length;
        beatIndex++
      ) {
        if (
          curSubGroup === null ||
          leadOffList.filter((value) =>
            hasOverlappedRange(value, {
              onsetWaveformIndex: group.waveformIndexList[beatIndex - 1],
              terminationWaveformIndex: group.waveformIndexList[beatIndex],
            })
          ).length > 0
        ) {
          curSubGroup = {
            prevBeatWaveformIndex:
              group.waveformIndexList[beatIndex - 1] ??
              group.prevBeatWaveformIndex,
            nextBeatWaveformIndex:
              group.waveformIndexList[beatIndex + 1] ??
              group.nextBeatWaveformIndex,
            beatType: group.beatType,
            onsetRPeakIndex: group.waveformIndexList[beatIndex],
            waveformIndexList: Array<WaveformIndex>(),
          };
          subList.push(curSubGroup);
        }
        curSubGroup.waveformIndexList.push(group.waveformIndexList[beatIndex]);
        curSubGroup.nextBeatWaveformIndex =
          group.waveformIndexList[beatIndex + 1] ?? group.nextBeatWaveformIndex;
      }
      return [
        ...acc,
        ...subList.filter((group) => group.waveformIndexList.length > 0),
      ];
    } else {
      return [...acc, group];
    }
  }, Array<BeatTypeGroup>());
  const step3 = step2.map((group) =>
    getBeatEvent(group, getOverLappedEventList)
  );

  return step3;
}
/** 같은 Beat Type 이 연속되는 Beat Group 목록 반환 */
function getBeatTypeGroupList(
  rawBeats: BeatsApiResponse
): Array<BeatTypeGroup> {
  let groupList = Array<BeatTypeGroup>();
  let currentGroup: BeatTypeGroup | null = null;

  for (let beatIndex = 0; beatIndex < rawBeats.beatType.length; beatIndex++) {
    if (
      currentGroup === null ||
      currentGroup.beatType !== rawBeats.beatType[beatIndex]
    ) {
      currentGroup = {
        prevBeatWaveformIndex: rawBeats.waveformIndex[beatIndex - 1],
        nextBeatWaveformIndex: rawBeats.waveformIndex[beatIndex + 1],
        beatType: rawBeats.beatType[beatIndex],
        onsetRPeakIndex: rawBeats.waveformIndex[beatIndex],
        waveformIndexList: Array<WaveformIndex>(),
      };
      groupList.push(currentGroup);
    }
    currentGroup.waveformIndexList.push(rawBeats.waveformIndex[beatIndex]);
    currentGroup.nextBeatWaveformIndex = rawBeats.waveformIndex[beatIndex + 1];
  }

  const result = groupList.filter(
    (group) => !(isNormalBeatGroup(group) || isEmptyBeatGroup(group))
  );

  return result;
}

/** Beat Event 정보를 생성 */
function getBeatEvent(
  group: BeatTypeGroup,
  getOverLappedEventList: InRangeFinder
): IBeatEvent {
  // onsetWaveformIndex, hasOnsetMarker, prevExclusive

  //      onsetTypeChangeRange.terminationWaveformIndex
  //      ↓
  //      ̲ ̲ ̲ ̲
  //  1  2  2  2  1
  //  ⎺⎺⎺
  //  ↑
  //  onsetTypeChangeRange.onsetWaveformIndex
  const onsetTypeChangeRange = {
    onsetWaveformIndex: group.prevBeatWaveformIndex,
    terminationWaveformIndex:
      group.waveformIndexList.at(0) ?? Number.MIN_SAFE_INTEGER,
  };
  const prevExclusive = getOverLappedEventList(
    onsetTypeChangeRange,
    group.beatType === BEAT_TYPE_APC
  ).reduce((acc: RangeOrUndefined, cur) => {
    if (
      (acc?.terminationWaveformIndex ?? Number.MIN_SAFE_INTEGER) <
      cur.terminationWaveformIndex
    ) {
      return cur;
    }
    return acc;
  }, undefined);
  const { markerWaveformIndex: onsetWaveformIndex, hasMarker: hasOnsetMarker } =
    prevExclusive
      ? {
          markerWaveformIndex: prevExclusive.terminationWaveformIndex,
          hasMarker: true,
        }
      : getEdgeMarkerValues(onsetTypeChangeRange);

  //               onsetTypeChangeRange.terminationWaveformIndex
  //               ↓
  //            ̲ ̲ ̲ ̲
  //  1  2  2  2  1
  //           ⎺⎺⎺
  //           ↑
  //           onsetTypeChangeRange.onsetWaveformIndex
  // terminationWaveformIndex, hasTerminationMarker, nextExclusive
  const terminationTypeChangeRange = {
    onsetWaveformIndex:
      group.waveformIndexList.at(-1) ?? Number.MAX_SAFE_INTEGER,
    terminationWaveformIndex: group.nextBeatWaveformIndex,
  };
  const nextExclusive = getOverLappedEventList(
    terminationTypeChangeRange,
    group.beatType === BEAT_TYPE_APC
  ).reduce((acc: RangeOrUndefined, cur) => {
    if (
      cur.onsetWaveformIndex <
      (acc?.onsetWaveformIndex ?? Number.MAX_SAFE_INTEGER)
    ) {
      return cur;
    }
    return acc;
  }, undefined);
  const {
    markerWaveformIndex: terminationWaveformIndex,
    hasMarker: hasTerminationMarker,
  } = nextExclusive
    ? {
        markerWaveformIndex: nextExclusive.onsetWaveformIndex,
        hasMarker: true,
      }
    : getEdgeMarkerValues(terminationTypeChangeRange);

  const ectopicType = getEctopicType(group.waveformIndexList);
  const result = {
    onsetRPeakIndex: group.onsetRPeakIndex,
    ectopicType,
    beatType: group.beatType,
    waveformIndex: group.waveformIndexList,
    type:
      getEventInfo({
        beatType: group.beatType,
        ectopicType,
      }).findOne()?.type ?? EVENT_CONST_TYPES.NOISE,
    //
    onsetWaveformIndex:
      onsetWaveformIndex ?? INITIAL_ONSET_MARKER_WAVEFORM_INDEX, // Onset Marker 위치 임의 초기값
    hasOnsetMarker,
    prevExclusive,
    //
    terminationWaveformIndex:
      terminationWaveformIndex ?? INITIAL_TERMINATION_MARKER_WAVEFORM_INDEX, // Termination Marker 위치 임의 초기값
    hasTerminationMarker,
    nextExclusive,
  };

  return result;
}

/** Beat Group 의 Beat Type 이 Normal 일경우 True 반환 */
function isNormalBeatGroup(group: BeatTypeGroup) {
  return group.beatType === BEAT_TYPE_NORMAL;
}
/** Beat Group 에 Beat 가 없을 경우 True 반환 */
function isEmptyBeatGroup(group: BeatTypeGroup) {
  if (group.waveformIndexList.length === 0) {
    console.error(
      'getBeatsNBeatEventsList error: Beat Group 구성 중 오류 발생'
    );
  }
  return group.waveformIndexList.length === 0;
}
