import {
  PoolingState,
  ShapeReviewSectionArea,
} from 'constant/ShapeReviewConst';

export function getWillBeDisplayedFormDataList({
  waveformIndexOfSelectedTarget,
  sliceInfoOfBeDisplayedFormData,
  poolingDataOfSelectedTarget,
}: {
  waveformIndexOfSelectedTarget: any;
  sliceInfoOfBeDisplayedFormData: { begin: number; end: number };
  poolingDataOfSelectedTarget: any;
}) {
  const beDisplayedWaveFormIndexList = waveformIndexOfSelectedTarget.slice(
    sliceInfoOfBeDisplayedFormData.begin,
    sliceInfoOfBeDisplayedFormData.end
  );

  let willBeDisplayedFormDataList: number[] = [];
  const millisecondTime = new Date().getTime();
  for (let index of beDisplayedWaveFormIndexList) {
    const indexData = poolingDataOfSelectedTarget.get(index);
    // indexData === 'pending': fetching 상태, indexData === undefined: 초기 설정 상태
    if (indexData !== PoolingState.PENDING && indexData !== undefined) {
      indexData.millisecondTime = millisecondTime;
      willBeDisplayedFormDataList.push(indexData);
    }
  }
  return willBeDisplayedFormDataList;
}

/**
 * 선택된 이벤트의 forms에서 beatEventDetail의 waveformIndex가 몇번째 index에 있는지 확인
 *
 * @param selectedEventFormListInfo
 * @param beatEventDetail
 * @returns
 */
export function findIndexOfBeatEventDetailInFromList(
  selectedEventFormListInfo: any,
  beatEventDetail: any
) {
  return selectedEventFormListInfo.forms.findIndex(
    (v: any) =>
      v.representativeWaveformIndex === beatEventDetail.originWaveformIndex
  );
}

export function getIndexOfNextPoolingInPrevCase(
  beginIndexOfCurrentPage: number,
  formsThumbNailWaveFormIndexOfClickedEvent: any,
  selectedEventFormPoolingData: any,
  limit: any
) {
  for (
    let cursor = beginIndexOfCurrentPage;
    cursor > (limit > 0 ? limit : 0);
    cursor--
  ) {
    const waveformIndexOfIndexInList =
      formsThumbNailWaveFormIndexOfClickedEvent[cursor];
    if (!selectedEventFormPoolingData.get(waveformIndexOfIndexInList)) {
      return cursor;
    }
  }
}

export function getIndexOfNextPooling(
  beginIndexOfCurrentPage: number,
  formsThumbNailWaveFormIndexOfClickedEvent: any,
  selectedEventFormPoolingData: any,
  limit: any
) {
  for (
    let cursor = beginIndexOfCurrentPage;
    cursor <
    (limit > formsThumbNailWaveFormIndexOfClickedEvent.length
      ? formsThumbNailWaveFormIndexOfClickedEvent.length
      : limit);
    cursor++
  ) {
    const waveformIndexOfIndexInList =
      formsThumbNailWaveFormIndexOfClickedEvent[cursor];
    if (!selectedEventFormPoolingData.get(waveformIndexOfIndexInList)) {
      return cursor;
    }
  }
}

export function getNewCurrentPageDependOnPoolingData({
  clickedEventPanelSizeInfo,
  newCurrenPage,
  action,
  state,
  clickedEventType,
  formPanelDetailOfFirstIndexOfLastSelectedSection,
  clickedTargetPoolingData,
  clickedEventCurrentPage,
}: {
  clickedEventPanelSizeInfo: any;
  newCurrenPage: number;
  action: { panelType: ShapeReviewSectionArea };
  state: any;
  clickedEventType: any;
  formPanelDetailOfFirstIndexOfLastSelectedSection: any;
  clickedTargetPoolingData: any;
  clickedEventCurrentPage: any;
}): number {
  const nextPageFirstItemIndex =
    clickedEventPanelSizeInfo.pageSize * (newCurrenPage - 1);
  let waveFormIndexOfNextPageFirstItemIndex;
  if (action.panelType === ShapeReviewSectionArea.FORMS) {
    waveFormIndexOfNextPageFirstItemIndex =
      state.clusterFormInfoList.data[clickedEventType]
        .formsThumbNailWaveFormIndex[nextPageFirstItemIndex];
  } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
    waveFormIndexOfNextPageFirstItemIndex =
      state.waveformIndexListInfoOfForms.data[clickedEventType][
        formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id
      ].onsetWaveformIndexListOfForm[nextPageFirstItemIndex];
  }

  const hasPoolingData = clickedTargetPoolingData.get(
    waveFormIndexOfNextPageFirstItemIndex
  );

  if (!hasPoolingData || hasPoolingData === PoolingState.PENDING) {
    newCurrenPage = clickedEventCurrentPage;
  }
  return newCurrenPage;
}

/**
 * 패딩된 배열을 반환합니다.
 * @param arr - 검증할 배열
 * @param targetLength - 최소 배열 길이
 * @param paddingLocation - 패딩을 추가할 위치 ('start' 또는 'end')
 * @returns 검증할 배열의 길이가 targetLength보다 작을 경우, 배열의 앞 또는 뒤에 0을 채운 배열을 반환합니다.
 */
export function padArrayWithZeros({
  arr,
  targetLength,
  paddingLocation,
}: {
  arr: number[];
  targetLength: number;
  paddingLocation: 'start' | 'end';
}): number[] {
  const currentLength = arr.length;

  if (currentLength >= targetLength) return arr;

  const zerosToAdd = targetLength - currentLength;
  const zeroArray: number[] = new Array(zerosToAdd).fill(0);

  if (paddingLocation === 'start') return [...zeroArray, ...arr];
  if (paddingLocation === 'end') return [...arr, ...zeroArray];
  return arr;
}

export function updatePoolingBeatType({
  poolingData,
  succeedWaveformIndexList,
  reqBeatType,
  formId,
  updatedPatchBeatByWaveFormIndexes,
}: {
  poolingData: any;
  succeedWaveformIndexList: any;
  reqBeatType: any;
  formId: any;
  updatedPatchBeatByWaveFormIndexes: any;
}) {
  const indexOfOnsetOriginWaveformIndex =
    poolingData.beats.waveformIndex.indexOf(poolingData.originWaveformIndex);
  const indexOfTerminationOriginWaveformIndex =
    indexOfOnsetOriginWaveformIndex + 1;

  if (succeedWaveformIndexList.includes(poolingData.originWaveformIndex)) {
    poolingData.beatType = reqBeatType;
    poolingData.beats.beatType[indexOfOnsetOriginWaveformIndex] = reqBeatType;
    updatedPatchBeatByWaveFormIndexes[formId] = [
      ...new Set(
        [
          ...(updatedPatchBeatByWaveFormIndexes?.[formId] || []),
          poolingData.originWaveformIndex,
        ].filter(Number)
      ),
    ];
  }

  if (
    succeedWaveformIndexList.includes(
      poolingData.beats.waveformIndex[indexOfTerminationOriginWaveformIndex]
    )
  ) {
    poolingData.beatType = reqBeatType;
    poolingData.beats.beatType[indexOfTerminationOriginWaveformIndex] =
      reqBeatType;
    updatedPatchBeatByWaveFormIndexes[formId] = [
      ...new Set(
        [
          ...(updatedPatchBeatByWaveFormIndexes?.[formId] || []),
          poolingData.originWaveformIndex,
        ].filter(Number)
      ),
    ];
  }
}

export function isFormIdInList(formIds: number[], selectedFormId: number) {
  return formIds.includes(selectedFormId);
}
