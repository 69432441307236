import { put, select } from 'redux-saga/effects';

import { TEN_SEC_STRIP_DETAIL } from 'constant/ChartEditConst';

import {
  _getFilterBeatsNEctopicList,
  getSearchBeatsNEctopicListRangeAfterUpdateEvent,
} from 'util/reduxDuck/TestResultDuckUtil';

import {
  getBeatsNEctopicListSucceed,
  postProcessBeatsNEctopicByRange,
  selectBeatsNEctopicList,
} from 'redux/duck/testResultDuck';
import { BeatType } from '@type/ecgEventType/baseEventType';
import {
  OptimisticEventDataUpdateForBeats,
  optimisticEventDataUpdateCase,
  optimisticEventDataUpdateForTimeEventParam,
} from '@type/optimisticUpdate/type';
import { BeatsNBeatEventsList } from '@type/beatNEctopic/process';

import { Command, eventUpdateInst } from '../../eventUpdateCmdPattern';
import { PatchBeatByWaveformIndexListCommand } from '../../beatEvent/commandPattern/eventReview_PatchBeatByWaveformIndexList';
import { OptimisticEventDataUpdateForNormal } from '../optimisticEventDataUpdateForNormal';

export class PostAfib extends OptimisticEventDataUpdateForNormal {
  result: any;
  reqParam: any;

  constructor(reqParam: any) {
    super();
    this.reqParam = reqParam;
  }

  *optimisticEventDataUpdate({
    updateReqOption,
  }: optimisticEventDataUpdateForTimeEventParam) {
    yield this._validation({ updateReqOption });
    yield this.updateLogic({ updateReqOption });
  }

  *_validation({
    updateReqOption,
  }: optimisticEventDataUpdateForTimeEventParam) {
    this.validation();

    // # validation List
    //   * validation1: filter s beat in af range witch will be added (af -> s(s 비트 n으로 변경))
    //     - STEP1. setting reqBody; figure out included s beat waveformIndexList
    //     - STEP2. [optimistic update(eventData)] af 구간에 있는 S beat를 N비트로 변경 optimistic update(eventData)
    //     - STEP3. [postProcess] setting BeatsNEctopicByRange
    const isRemove = updateReqOption.isRemove;
    if (!isRemove) {
      // validation1: filter s beat in af range witch will be added (af -> s(s 비트 n으로 변경))
      const staleBeatsNBeatEventsList: any[] = yield select(
        selectBeatsNEctopicList
      );
      const selectionStripOnsetWaveformIndex =
        updateReqOption.onsetWaveformIndex;
      const selectionStripTerminationWaveformIndex =
        updateReqOption.terminationWaveformIndex;
      // selection strip 구간을 포함하는 '정규 30초구간'에 해당하는 beatNEctopicList 반환
      let filterBeatsNEctopicList = _getFilterBeatsNEctopicList(
        staleBeatsNBeatEventsList,
        selectionStripOnsetWaveformIndex,
        selectionStripTerminationWaveformIndex
      );

      const result: OptimisticEventDataUpdateForBeats = {
        beatType: [],
        hr: [],
        waveformIndex: [],
      };
      let mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats =
        filterBeatsNEctopicList.reduce((acc, cur) => {
          acc.beatType = [...acc.beatType, ...cur.beats.beatType];
          acc.hr = [...acc.hr, ...cur.beats.hr];
          acc.waveformIndex = [
            ...acc.waveformIndex,
            ...cur.beats.waveformIndex,
          ];
          return acc;
        }, result);

      // STEP1. setting reqBody; figure out included s beat waveformIndexList
      let startIndex = mergedBeatsNEctopicList.waveformIndex.findIndex(
        (index) => index >= selectionStripOnsetWaveformIndex
      );
      let endIndex =
        mergedBeatsNEctopicList.waveformIndex.findIndex(
          (index) => index > selectionStripTerminationWaveformIndex
        ) - 1;
      let indexListOfOne: number[] = [];
      mergedBeatsNEctopicList.beatType.forEach((beatType, index) => {
        if (index >= startIndex && index <= endIndex && beatType === 1) {
          indexListOfOne.push(index);
        }
      });
      const resultFilter = mergedBeatsNEctopicList.waveformIndex.filter(
        (wfi, idx) => {
          if (indexListOfOne.includes(idx)) return true;
          return false;
        }
      );
      const reqBody = {
        beatType: BeatType.NORMAL,
        waveformIndexes: resultFilter,
      };

      if (resultFilter.length === 0) return;

      // STEP2. [optimistic update(eventData)] af 구간에 있는 S beat를 N비트로 변경 optimistic update(eventData)
      let eventUpdateCommandInst = new PatchBeatByWaveformIndexListCommand({
        updateReqOption: {
          optimisticEventDataUpdateCase:
            optimisticEventDataUpdateCase.patchBeatsByWaveformIndexList,
          reqBody,
          tabType: TEN_SEC_STRIP_DETAIL.TAB.ARRHYTHMIA_CONTEXTMENU,
        },
        filterBeatsNEctopicList,
      });
      yield eventUpdateInst.execute(eventUpdateCommandInst);
      mergedBeatsNEctopicList = eventUpdateCommandInst.getResult().result;
      // eventUpdateCommandInst = null;

      // STEP3. [postProcess] setting BeatsNEctopicByRange
      const { searchOnsetRequest, searchTerminationRequest } =
        getSearchBeatsNEctopicListRangeAfterUpdateEvent(
          selectionStripOnsetWaveformIndex,
          selectionStripTerminationWaveformIndex
        ) as { searchOnsetRequest: number; searchTerminationRequest: number };
      const {
        totalFreshBeatsNBeatEventsList,
      }: {
        totalFreshBeatsNBeatEventsList: BeatsNBeatEventsList;
        freshBeatsNBeatEventsList: BeatsNBeatEventsList;
      } = yield* postProcessBeatsNEctopicByRange(
        searchOnsetRequest,
        searchTerminationRequest,
        mergedBeatsNEctopicList
      );

      yield put(getBeatsNEctopicListSucceed(totalFreshBeatsNBeatEventsList));
    }
  }

  *updateLogic({
    updateReqOption,
  }: optimisticEventDataUpdateForTimeEventParam) {
    yield* this.optimisticEventData({
      updateReqOption,
    });
  }

  getResult() {
    return this.result;
  }
}

// ### COMMAND 역할
// todo: jyoon - command interface 만들어서
export class PostAfibCommand {
  command: any;
  executeInst: any;

  constructor(value: any) {
    this.command = new Command(PostAfib, null, value);
  }

  *execute() {
    this.executeInst = new this.command.executeClass(this.command.value);
    yield this.executeInst.optimisticEventDataUpdate(this.command.value);
  }

  getResult() {
    return this.executeInst.getResult();
  }
}
