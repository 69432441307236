import { all, put, select } from 'redux-saga/effects';
import rfdc from 'rfdc';

import { ECG_CHART_UNIT } from 'constant/ChartEditConst';
import { SUFFIX_LIST } from 'constant/EventConst';

import {
  setMovePosition,
  updateBeatTypeOfRawAndEventList,
  selectSidePanelState,
  selectClickedEventType,
  selectRawAndEventListState,
  selectCurrentPositionIndex,
  selectTenSecStripWaveformIndexList,
  selectEventOnsetWaveformIndexListState,
} from 'redux/duck/beatReviewDuck';
import { BeatType, EventConstTypes } from '@type/ecgEventType/baseEventType';
import {
  OptimisticEventDataUpdateForBeats,
  hrValue,
  optimisticEventDataUpdateCase,
} from '@type/optimisticUpdate/type';
import { WaveformIndex } from '@type/ecgEventType/eventUnit';
import { EventEditValidation } from '@type/optimisticUpdate/validation';
import { BeatDetailInfoMap } from '@type/ecgEventType/eventType';

import { beatReviewUpdatePostProcess } from './beatReview_UpdateProcess';

import { optimisticEventDataUpdate } from '../optimisticEventDataUpdate';

const TEN_SEC_WAVEFORM_IDX: number =
  ECG_CHART_UNIT.TEN_SEC_WAVEFORM_IDX as number;

export class BeatReview extends optimisticEventDataUpdate {
  rfdcClone;
  tabType = 'BeatReview';
  callStack: UpdateEventDataParam[];
  result: any;
  constructor(param: any) {
    super();
    this.rfdcClone = rfdc();
    this.callStack = [];
  }

  *updateEventData({
    clickedEventType,
    currentPositionIndex,
    optimisticEventDataUpdateOption,
    eventUpdateFn,
  }: UpdateEventDataParam): Generator<any, any, any> {
    this.callStack.push({
      clickedEventType,
      currentPositionIndex,
      optimisticEventDataUpdateOption,
      eventUpdateFn,
    });

    const [eventOnsetWaveformIndexList, rawAndEventList] = yield all([
      select(selectEventOnsetWaveformIndexListState),
      select(selectRawAndEventListState),
    ]);

    const onsetWaveformIndexListOfClickedEventType =
      eventOnsetWaveformIndexList[clickedEventType];
    const waveformIndexOfCurrentPositionIndex =
      onsetWaveformIndexListOfClickedEventType[currentPositionIndex] as number;
    const rawAndEventListOfClickedEventType = rawAndEventList[
      clickedEventType
    ] as BeatDetailInfoMap;

    let updateTarget: BeatDetailInfoMap = {}; // update event in current position and pooling data
    for (let waveformIndex in rawAndEventListOfClickedEventType) {
      const numericWaveformIndex = Number(waveformIndex);
      if (
        numericWaveformIndex >=
          waveformIndexOfCurrentPositionIndex - TEN_SEC_WAVEFORM_IDX &&
        numericWaveformIndex <=
          waveformIndexOfCurrentPositionIndex + TEN_SEC_WAVEFORM_IDX
      ) {
        updateTarget[numericWaveformIndex] =
          rawAndEventListOfClickedEventType[numericWaveformIndex];
      }
    }

    let updateDataObject: BeatDetailInfoMap = {}; // update event in current event
    const copyUpdateTarget = this.rfdcClone(updateTarget);
    const updateWaveformIndexList =
      optimisticEventDataUpdateOption.waveformIndex;
    for (let i = 0; i < updateWaveformIndexList.length; i++) {
      const editedEventWaveformIndex = updateWaveformIndexList[i];
      for (let representativeWaveformIndex in copyUpdateTarget) {
        // 분석3:
        const updatedEvent = eventUpdateFn({
          updateTarget: copyUpdateTarget[representativeWaveformIndex],
          editedEventWaveformIndex: editedEventWaveformIndex,
          updateWaveformIndexList: optimisticEventDataUpdateOption,
          updateIndex: Number(i),
        });

        updateDataObject[representativeWaveformIndex] = updatedEvent;
      }
    }
    // console.timeEnd('2');
    yield put(
      updateBeatTypeOfRawAndEventList({
        updateData: updateDataObject,
        clickedEventType,
      })
    );

    const [freshClickedEventType, freshCurrentPositionIndex] = yield all([
      select(selectClickedEventType),
      select(selectCurrentPositionIndex),
    ]);
    if (
      freshClickedEventType === clickedEventType &&
      freshCurrentPositionIndex === currentPositionIndex
    ) {
      yield put(
        setMovePosition({ positionMoveType: '', currentPositionIndex })
      );
    }

    return {
      isValidationSuccessful: true,
      result: optimisticEventDataUpdateOption,
    };
  }

  *post({
    type,
    action,
  }: OptimisticEventDataUpdateReqParamBase): Generator<
    any,
    beatReviewOptimisticUpdateResult,
    any
  > {
    const { clickedEventType, currentPositionIndex }: SidePanelState =
      yield this.getCurrentSidePanelState();
    const validationInfoList = [
      { type: EventEditValidation.S_BEAT_IN_AF, fn: this.filterSBeatInAf },
      { type: EventEditValidation.OVER_HR_LIMIT, fn: this.filterOverHrLimit },
    ];

    const optimisticEventDataUpdateOption: OptimisticEventDataUpdateForBeats =
      yield this.filterEditableWaveformIndex({
        type,
        action,
        validationInfoList,
      });
    const updateResult: beatReviewOptimisticUpdateResult =
      yield this.updateEventData({
        clickedEventType,
        currentPositionIndex,
        optimisticEventDataUpdateOption,
        eventUpdateFn: beatReviewUpdatePostProcess.postBeatEventUpdate,
      });

    return updateResult;
  }

  *patch({
    type,
    action,
  }: OptimisticEventDataUpdateReqParamBase): Generator<
    any,
    beatReviewOptimisticUpdateResult,
    any
  > {
    const { clickedEventType, currentPositionIndex }: SidePanelState =
      yield this.getCurrentSidePanelState();
    const validationInfoList = [
      { type: EventEditValidation.S_BEAT_IN_AF, fn: this.filterSBeatInAf },
    ];

    const optimisticEventDataUpdateOption: OptimisticEventDataUpdateForBeats =
      yield this.filterEditableWaveformIndex({
        type,
        action,
        validationInfoList,
      });

    const updateResult: beatReviewOptimisticUpdateResult =
      yield this.updateEventData({
        clickedEventType,
        currentPositionIndex,
        optimisticEventDataUpdateOption,
        eventUpdateFn: beatReviewUpdatePostProcess.patchBeatEventUpdate,
      });

    return updateResult;
  }

  *delete({ type, action }: OptimisticEventDataUpdateReqParamBase) {
    const { clickedEventType, currentPositionIndex } =
      yield this.getCurrentSidePanelState();
    const updateData = { waveformIndex: action.reqBody.waveformIndexes };

    yield this.updateEventData({
      clickedEventType,
      currentPositionIndex,
      optimisticEventDataUpdateOption: updateData,
      eventUpdateFn: beatReviewUpdatePostProcess.deleteBeatEventUpdate,
    });
  }

  *getCurrentSidePanelState() {
    const {
      clickedInfo: { eventType: clickedEventType },
      currentPositionIndexEachEventType,
    }: {
      clickedInfo: { eventType: EventType };
      currentPositionIndexEachEventType: { [key in EventType]: number };
    } = yield select(selectSidePanelState);
    const currentPositionIndex: number =
      currentPositionIndexEachEventType[clickedEventType];

    return { clickedEventType, currentPositionIndex } as SidePanelState;
  }

  *filterEditableWaveformIndex({
    type,
    action,
    validationInfoList = [],
  }: OptimisticEventDataUpdateReqParamFilterStartingPoint): any {
    let updateTargetWaveformIndexList = action.reqBody.waveformIndexes;

    for (let validationInfo of validationInfoList) {
      // place of figure out executing api call validation
      updateTargetWaveformIndexList = yield validationInfo.fn.call(this, {
        type,
        action,
        waveformIndexes: updateTargetWaveformIndexList,
      });

      if (updateTargetWaveformIndexList.length === 0) {
        if (!Array.isArray(action.validationFalseList)) {
          action.validationFalseList = [];
        }
        action.validationFalseList.push(validationInfo.type);
      }
    }

    let optimisticEventDataUpdateOption: OptimisticEventDataUpdateForBeats = {
      beatType: [],
      hr: [],
      waveformIndex: [],
    };
    for (let waveformIndex of updateTargetWaveformIndexList) {
      optimisticEventDataUpdateOption.beatType.push(action.reqBody.beatType);
      optimisticEventDataUpdateOption.hr.push(
        hrValue.optimisticEventDataUpdated
      );
      optimisticEventDataUpdateOption.waveformIndex.push(waveformIndex);
    }
    return optimisticEventDataUpdateOption;
  }

  *filterSBeatInAf({
    type,
    action,
    waveformIndexes,
  }: OptimisticEventDataUpdateReqParamFilterItem): any {
    if (action.reqBody.beatType !== BeatType.APC) {
      return waveformIndexes;
    }

    const [afList, recordingStartMs] = yield all([
      this.getAfList(),
      this.getRecordingStartMs(),
    ]);

    const result = waveformIndexes.filter(
      (waveformIndex: number) =>
        !this.validateSBeatInAf({
          afList,
          waveformIndex,
          recordingStartMs,
        })
    );

    return result;
  }

  *filterOverHrLimit({
    type,
    action,
    waveformIndexes,
  }: OptimisticEventDataUpdateReqParamFilterItem) {
    if (type !== optimisticEventDataUpdateCase.postBeats) {
      return waveformIndexes;
    }

    const tenSecStripWaveformIndexList: WaveformIndex[] = yield select(
      selectTenSecStripWaveformIndexList
    );
    const result = waveformIndexes.filter(
      (waveformIndex) =>
        !this.validateOverHrLimit({
          updateTargetWaveformIndex: waveformIndex,
          comparisonWaveformIndexList: tenSecStripWaveformIndexList,
        })
    );

    return result;
  }

  validateExecution({
    optimisticEventDataUpdateOption,
  }: {
    optimisticEventDataUpdateOption: OptimisticEventDataUpdateForBeats;
  }): {
    isValidationSuccessful: boolean;
    result: OptimisticEventDataUpdateForBeats;
  } {
    const isValidationSuccessful =
      optimisticEventDataUpdateOption.waveformIndex.length !== 0;

    return {
      isValidationSuccessful,
      result: optimisticEventDataUpdateOption,
    };
  }

  error() {}
}

export interface UpdateEventDataParam {
  clickedEventType: any;
  currentPositionIndex: number;
  optimisticEventDataUpdateOption: any;
  eventUpdateFn: any;
}

export type EventType = Extract<
  typeof EventConstTypes,
  | typeof EventConstTypes.ISO_APC
  | typeof EventConstTypes.ISO_VPC
  | typeof EventConstTypes.COUPLET_APC
  | typeof EventConstTypes.COUPLET_VPC
>;

interface SidePanelState {
  clickedEventType: EventType;
  currentPositionIndex: number;
}

interface ReqBody {
  waveformIndexes: WaveformIndex[];
  beatType: BeatType;
}

type EditBeatSuffix =
  typeof SUFFIX_LIST.EDIT_BEAT_SUFFIX[keyof typeof SUFFIX_LIST.EDIT_BEAT_SUFFIX];

interface Action {
  type: string;
  reqBody: ReqBody;
  onsetWaveformIndex: WaveformIndex;
  terminationWaveformIndex: WaveformIndex;
  suffix: EditBeatSuffix;
  tabType: string;
  validationFalseList: EventEditValidation['type'][];
}

export interface OptimisticEventDataUpdateReqParamBase {
  type: Extract<
    optimisticEventDataUpdateCase,
    | typeof optimisticEventDataUpdateCase.postBeats
    | typeof optimisticEventDataUpdateCase.patchBeatsByWaveformIndexList
    | typeof optimisticEventDataUpdateCase.deleteBeats
  >;
  action: Action;
}
export interface OptimisticEventDataUpdateReqParamFilterStartingPoint
  extends OptimisticEventDataUpdateReqParamBase {
  validationInfoList?: any[];
}
export interface OptimisticEventDataUpdateReqParamFilterItem
  extends OptimisticEventDataUpdateReqParamBase {
  waveformIndexes: WaveformIndex[];
}

interface beatReviewOptimisticUpdateResult {
  isValidationSuccessful: boolean;
  result: OptimisticEventDataUpdateReqParamBase;
}
