import { call, put, select } from 'redux-saga/effects';

import { TIME_EVENT_TYPE } from 'constant/EventConst';

import {
  getBeatsNEctopicListRequested,
  selectEcgRawList,
} from 'redux/duck/testResultDuck';

const CONST_TIME_EVENT_TYPE = TIME_EVENT_TYPE;

/**
 * @param {TimeEventsPostRequestBodyType} requestBody
 */
export function* postProcessEditedTimeEvent({
  params,
  releaseRequestedInfoAction,
}) {
  // console.log('#### postProcessEditedTimeEvent > params: ', params);
  switch (params.eventType) {
    case CONST_TIME_EVENT_TYPE.AF:
      yield call(postProcessForAf, params, releaseRequestedInfoAction);
      return;
    case CONST_TIME_EVENT_TYPE.PAUSE:
      return;
    case CONST_TIME_EVENT_TYPE.OTHERS:
      return;
    default:
      return;
  }
}

/**
 * af 편집시 apc 관련 validation으로 인해 af 편집 구간에 대해서 getBeatsNEctopicListRequested redux action creator를 호출해 새로운 데이터를 받습니다.
 *  - 아래 작성된 내용(af 편집시 apc 관련 validation)중 2번에 해당하는 내용을 구현하기 위한 로직 입니다.
 *  - af 편집시 apc 관련 validation
 *    1. apc 이벤트가 있는 구간에 af를 설정하면 af 이벤트만 보여줘야 합니다.
 *    2. apc 이벤트가 있는 구간에 af 구간을 해제 했을때 apc 이벤트를 보여줘야 합니다.
 *
 * @param {TimeEventsPostRequestBodyType} requestBody
 */
function* postProcessForAf(
  { onsetWaveformIndex, terminationWaveformIndex },
  releaseRequestedInfoAction
) {
  // console.log('&&& check - during call postProcessEditedTimeEvent');
  const ecgRawList = yield select((state) => selectEcgRawList(state));
  const intersectionList = ecgRawList
    .filter((ecgRawInfo) => {
      return isIncludedSelectionMarkerSectionInEcgRawRegular30SecSection({
        selectionMarkerSection: {
          onsetWaveformIndex,
          terminationWaveformIndex,
        },
        ecgRawRegular30SecSection: {
          onsetWaveformIndex: ecgRawInfo.onsetWaveformIndex,
          terminationWaveformIndex: ecgRawInfo.terminationWaveformIndex,
        },
      });
    })
    .map((filteredEcgRawInfo) => ({
      onsetWaveformIndex: filteredEcgRawInfo.onsetWaveformIndex,
      terminationWaveformIndex: filteredEcgRawInfo.terminationWaveformIndex,
    }));

  const mergedSectionList = mergeContinuousSections(intersectionList);
  for (const {
    onsetWaveformIndex,
    terminationWaveformIndex,
  } of mergedSectionList) {
    yield put(
      getBeatsNEctopicListRequested(
        onsetWaveformIndex,
        terminationWaveformIndex,
        { releaseRequestedInfoAction }
      )
    );
  }
}

/**
 * selectionMarkerSection 구간을 포함한 ecgRawRegular30SecSection를 filter
 *
 * @param {SectionType} selectionMarkerSection selection marker 구간 정보(onset, termination)
 * @param {SectionType} ecgRawRegular30SecSection 정규 30초 구간 정보(onset, termination)
 * @returns
 */
function isIncludedSelectionMarkerSectionInEcgRawRegular30SecSection({
  selectionMarkerSection,
  ecgRawRegular30SecSection,
}) {
  /**
   *    # isIncludedSelectionMarkerSectionInEcgRawRegular30SecSectionCondition의 의미
   *      * 아래 표의 "=...="으로 표기된 구간을 의미 함
   *   ┌──────────────────────────────────────────────────────────────────────────────────────────────────────┐
   *   │                                                                                                     │
   *   │    ecgRawRegular30SecSection.onsetWaveformIndex                                                     │
   *   │   ↓                                                                                                 │
   *   │   ---------------------------------------------                                                     │
   *   │   -------------------[=========================                                                     │
   *   │                      ㄴ selectionMarkerSection.onsetWaveformIndex                                   │
   *   │   =============================================                                                     │
   *   │   =============================================                                                     │
   *   │   ===============================]-------------                                                     │
   *   │                                  ㄴ selectionMarkerSection.terminationWaveformIndex                 │
   *   │   ---------------------------------------------                                                     │
   *   │   ---------------------------------------------                                                     │
   *   │                                               ⇡                                                     │
   *   │                                               ecgRawRegular30SecSection.terminationWaveformIndex    │
   *   │                                                                                                     │
   *   └─────────────────────────────────────────────────────────────────────────────────────────────────────┘
   */
  const isIncludedSelectionMarkerSectionInEcgRawRegular30SecSectionCondition =
    !(
      selectionMarkerSection.onsetWaveformIndex >=
        ecgRawRegular30SecSection.terminationWaveformIndex ||
      selectionMarkerSection.terminationWaveformIndex <=
        ecgRawRegular30SecSection.onsetWaveformIndex
    );

  return (
    validateSectionInfo(selectionMarkerSection) &&
    validateSectionInfo(ecgRawRegular30SecSection) &&
    isIncludedSelectionMarkerSectionInEcgRawRegular30SecSectionCondition
  );
}

/**
 * 구간 정보의 값이 올바른지 검증
 *
 * @param {SectionType} section
 */
function validateSectionInfo({ onsetWaveformIndex, terminationWaveformIndex }) {
  return (
    typeof (onsetWaveformIndex + terminationWaveformIndex) === 'number' &&
    !Number.isNaN(onsetWaveformIndex + terminationWaveformIndex)
  );
}

/**
 * sectionList중 연속하는 Section을 하나의 정보로 병합
 * 두 Section의 연속은 서로 다른 Section의 terminationWaveformIndex 과 onsetWaveformIndex 이 같은 경우
 *
 * @param {SectionType[]} sectionList
 * @returns {SectionType[]}
 */
function mergeContinuousSections(sectionList) {
  const mergedSections = [];

  for (const section of sectionList) {
    const lastSection = mergedSections[mergedSections.length - 1];
    const doesNotExistLastSection = !lastSection;
    const doesNotContinueSection =
      lastSection?.terminationWaveformIndex !== section?.onsetWaveformIndex;

    if (doesNotExistLastSection || doesNotContinueSection) {
      mergedSections.push({ ...section });
    } else {
      lastSection.terminationWaveformIndex = section.terminationWaveformIndex;
    }
  }

  return mergedSections;
}

/**
 * @typedef {Number} WaveformIndex Waveform Index
 *
 * @typedef SectionType 구간 정보 타입
 * @property {WaveformIndex} onsetWaveformIndex
 * @property {WaveformIndex} terminationWaveformIndex
 *
 * @typedef TimeEventsPostRequestBodyType Time Events API 요청 데이터 타입
 * @property {TIME_EVENT_TYPE} eventType
 * @property {Boolean} isRemove
 * @property {WaveformIndex} onsetWaveformIndex
 * @property {WaveformIndex} terminationWaveformIndex
 * @property {String} tid
 *
 */
