import {
  OptimisticEventDataUpdateForBeats,
  hrValue,
} from '@type/optimisticUpdate/type';

import {
  ReqBody,
  UpdateReqOptionProps,
  eventReviewOptimisticEventDataUpdateForBeatEvent,
} from '../optimisticEventDataUpdateForBeatEvent';
import { Command } from '../../eventUpdateCmdPattern';

export class DeleteBeat extends eventReviewOptimisticEventDataUpdateForBeatEvent {
  result: any;
  reqParam: any;

  constructor(reqParam: any) {
    super();
    this.reqParam = reqParam;
  }

  optimisticEventDataUpdate({
    updateReqOption,
    filterBeatsNEctopicList,
  }: UpdateReqOptionProps) {
    let result: OptimisticEventDataUpdateForBeats = {
      hr: [],
      beatType: [],
      waveformIndex: [],
    };
    const { reqBody } = updateReqOption;
    const mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats =
      this.mergeBeatsNEctopicList(filterBeatsNEctopicList);

    // STEP1: beatUpdate
    result = this.updateLogic({ reqBody, mergedBeatsNEctopicList });

    this.result = this.setOptimisticEventDataUpdateResult({
      isValidationSuccessful: true,
      result,
    });
  }

  updateLogic({
    reqBody,
    mergedBeatsNEctopicList,
  }: {
    reqBody: ReqBody;
    mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats;
  }): OptimisticEventDataUpdateForBeats {
    const {
      hr: hrList,
      beatType: beatTypeList,
      waveformIndex: waveformIndexList,
    } = mergedBeatsNEctopicList;

    reqBody.waveformIndexes.forEach((updateTargetWaveform) => {
      const updateTargetWaveformIndexIndex = waveformIndexList.findIndex(
        (waveformIndex) => waveformIndex === updateTargetWaveform
      );

      if (updateTargetWaveformIndexIndex > -1) {
        beatTypeList.splice(updateTargetWaveformIndexIndex, 1);
        waveformIndexList.splice(updateTargetWaveformIndexIndex, 1);
        // optimistic update시 10s strip detail에 비트 삭제한 뒤
        // hr은 beat 정보를 새로 불러와야 하기 위해서 아래 "optimisticEventDataUpdated"로 설정
        if (hrList.length === 1) {
          hrList.splice(updateTargetWaveformIndexIndex, 1);
        } else if (hrList.length === 2) {
          hrList.splice(updateTargetWaveformIndexIndex, 1);
          hrList[updateTargetWaveformIndexIndex] =
            hrValue.optimisticEventDataUpdated;
        } else if (hrList.length > 3) {
          hrList.splice(updateTargetWaveformIndexIndex, 1);
          hrList[updateTargetWaveformIndexIndex - 1] =
            hrValue.optimisticEventDataUpdated;
          hrList[updateTargetWaveformIndexIndex] =
            hrValue.optimisticEventDataUpdated;
        }
      }
    });

    return mergedBeatsNEctopicList;
  }

  _validation() {}

  getResult() {
    return this.result;
  }
}

// ### COMMAND 역할
export class DeleteBeatCommand {
  command: any;
  executeInst: any;

  constructor(value: any) {
    this.command = new Command(DeleteBeat, null, value);
  }

  *execute() {
    this.executeInst = new this.command.executeClass(this.command.value);
    yield this.executeInst.optimisticEventDataUpdate(this.command.value);
  }

  getResult() {
    return this.executeInst.getResult();
  }
}
