import { select } from 'redux-saga/effects';

import { EVENT_CONST_TYPES } from 'constant/EventConst';

import {
  selectRecordingTime,
  selectTimeEventList,
} from 'redux/duck/testResultDuck';
import {
  OptimisticEventDataUpdateForBeats,
  hrValue,
} from '@type/optimisticUpdate/type';
import { Ms } from '@type/ecgEventType/eventUnit';
import { BeatType } from '@type/ecgEventType/baseEventType';
import { TimeEvent } from '@type/ecgEventType/eventType';
import { EventEditValidation } from '@type/optimisticUpdate/validation';

import { Validation } from './eventReview_PatchBeat';

import {
  ReqBody,
  UpdateReqOption,
  UpdateReqOptionProps,
  eventReviewOptimisticEventDataUpdateForBeatEvent,
} from '../optimisticEventDataUpdateForBeatEvent';
import { Command } from '../../eventUpdateCmdPattern';

export class PostBeat extends eventReviewOptimisticEventDataUpdateForBeatEvent {
  result: any;
  reqParam: any;

  constructor(reqParam: any) {
    super();
    this.reqParam = reqParam;
    this.result = { isValidationSuccessful: true };
  }

  *optimisticEventDataUpdate({
    updateReqOption,
    filterBeatsNEctopicList,
  }: UpdateReqOptionProps) {
    const { reqBody } = updateReqOption;
    const mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats =
      this.mergeBeatsNEctopicList(filterBeatsNEctopicList);

    // # optimistic update와 api 호출을 구분 가능하도록 하는 코드
    // const executeValidationResult: EventEditValidation[] =
    //   yield this.validateExecution({
    //     updateReqOption,
    //     mergedBeatsNEctopicList,
    //   });
    // if (executeValidationResult.length !== 0) {
    //   console.log('executeValidationResult: ', executeValidationResult);
    //   this.result = {
    //     isValidationSuccessful: false,
    //     result: executeValidationResult,
    //   };
    //   return this.result;
    // }

    let result: OptimisticEventDataUpdateForBeats = {
      hr: [],
      beatType: [],
      waveformIndex: [],
    };
    // STEP1: beatUpdate
    result = this.updateLogic({ reqBody, mergedBeatsNEctopicList });
    // STEP2: validation
    result = yield this._validation({
      reqBody,
      updateReqOption,
      mergedBeatsNEctopicList: result,
    });

    this.result = this.setOptimisticEventDataUpdateResult({
      isValidationSuccessful: true,
      result,
    });
  }

  updateLogic({
    reqBody,
    mergedBeatsNEctopicList,
  }: {
    reqBody: ReqBody;
    mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats;
  }): OptimisticEventDataUpdateForBeats {
    const {
      hr: hrList,
      beatType: beatTypeList,
      waveformIndex: waveformIndexList,
    } = mergedBeatsNEctopicList;

    for (let idx = 0; idx < reqBody.waveformIndexes.length; idx++) {
      const updateTargetWaveform = reqBody.waveformIndexes[idx];
      const updateTargetWaveformIndexIndex: number =
        waveformIndexList.findIndex(
          (waveformIndex) => waveformIndex > updateTargetWaveform
        );

      /**
       * √: beat 위치
       *
       * ---√---√---√---√---√---√---√---√---√---√---   <= 30s strip
       *                                           ↑
       *                   여기에 비트 추가시 updateTargetWaveformIndexIndex는 -1이다.
       *
       */
      if (updateTargetWaveformIndexIndex === -1) {
        // 30s strip 제일 마지막 비트 다음에 비트를 추가할 때
        hrList.push(hrValue.optimisticEventDataUpdated);
        beatTypeList.push(reqBody.beatType);
        waveformIndexList.push(updateTargetWaveform);
      } else {
        hrList.splice(
          updateTargetWaveformIndexIndex,
          0,
          hrValue.optimisticEventDataUpdated
        );
        beatTypeList.splice(
          updateTargetWaveformIndexIndex,
          0,
          reqBody.beatType
        );
        waveformIndexList.splice(
          updateTargetWaveformIndexIndex,
          0,
          updateTargetWaveform
        );
      }

      hrList.splice(
        updateTargetWaveformIndexIndex + 1,
        0,
        hrValue.optimisticEventDataUpdated
      );
    }

    return mergedBeatsNEctopicList;
  }

  *validateExecution({
    updateReqOption,
    mergedBeatsNEctopicList,
  }: {
    updateReqOption: UpdateReqOption;
    mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats;
  }): Generator<any, any, unknown> | boolean {
    // # validation List
    //  * validation1: prevent over 400 bpm when add beat
    //  * validation2: prevent add s beat in a-fib event

    let result: { type: string; msg: string }[] = [];
    const { reqBody } = updateReqOption;

    // validation1: prevent over 400 bpm when add beat
    const comparisonWaveformIndexList = mergedBeatsNEctopicList.waveformIndex;
    const validateOverHrLimit = reqBody.waveformIndexes.some((waveformIndex) =>
      this.validateOverHrLimit({
        updateTargetWaveformIndex: waveformIndex,
        comparisonWaveformIndexList,
      })
    );

    if (!validateOverHrLimit) {
      result.push(EventEditValidation.OVER_HR_LIMIT);
    }

    // validation2: prevent add s beat in a-fib event
    if (reqBody.beatType === BeatType.APC) {
      const afList: TimeEvent[] = yield this.getAfList();
      const recordingStartMs: Ms = yield this.getRecordingStartMs();

      const validateSBeatInAf = reqBody.waveformIndexes.some(
        (waveformIndex) =>
          !this.validateSBeatInAf({
            afList,
            waveformIndex,
            recordingStartMs,
          })
      );

      if (validateSBeatInAf) {
        result.push(EventEditValidation.S_BEAT_IN_AF);
      }
    }

    return result;
  }

  *_validation({
    reqBody,
    updateReqOption,
    mergedBeatsNEctopicList,
  }: Omit<Validation, 'copyMergedBeatsNEctopicList'>) {
    const {
      hr: hrList,
      beatType: beatTypeList,
      waveformIndex: waveformIndexList,
    } = mergedBeatsNEctopicList;

    const comparisonWaveformIndexList =
      mergedBeatsNEctopicList.waveformIndex.filter(
        (v) => !reqBody.waveformIndexes.includes(v)
      );
    const overHrLimitList = reqBody.waveformIndexes.filter((waveformIndex) =>
      this.validateOverHrLimit({
        updateTargetWaveformIndex: waveformIndex,
        comparisonWaveformIndexList,
      })
    );

    if (overHrLimitList.length > 0) {
      for (let idx = 0; idx < reqBody.waveformIndexes.length; idx++) {
        const curWaveformIndex = reqBody.waveformIndexes[idx];
        const indexOfCurWaveformIndex =
          mergedBeatsNEctopicList.waveformIndex.indexOf(curWaveformIndex);
        if (indexOfCurWaveformIndex > -1) {
          hrList.splice(indexOfCurWaveformIndex, 1);
          beatTypeList.splice(indexOfCurWaveformIndex, 1);
          waveformIndexList.splice(indexOfCurWaveformIndex, 1);
        }
      }
    }

    if (reqBody.beatType === BeatType.APC) {
      const EVENT_CONST_TYPE_AF = EVENT_CONST_TYPES.AF;
      const { recordingStartMs } = yield select(selectRecordingTime);
      const afList: TimeEvent[] = yield select((state) =>
        selectTimeEventList(state, EVENT_CONST_TYPE_AF)
      );

      for (let idx = 0; idx < reqBody.waveformIndexes.length; idx++) {
        const updateTargetWaveform = reqBody.waveformIndexes[idx];
        const updateTargetWaveformIndexIndex = waveformIndexList.findIndex(
          (waveformIndex) => waveformIndex === updateTargetWaveform
        );
        const selectedEventTimeStamp =
          recordingStartMs + updateTargetWaveform * 4;
        let result = false;
        for (let afInfo of afList) {
          if (
            selectedEventTimeStamp >= afInfo.onsetMs &&
            selectedEventTimeStamp <= afInfo.terminationMs
          ) {
            result = true;
            break;
          }
        }
        if (result) {
          hrList.splice(updateTargetWaveformIndexIndex, 1);
          beatTypeList.splice(updateTargetWaveformIndexIndex, 1);
          waveformIndexList.splice(updateTargetWaveformIndexIndex, 1);
        }
      }
    }

    return mergedBeatsNEctopicList;
  }

  getResult() {
    return this.result;
  }
}

// ### COMMAND 역할
export class PostBeatCommand {
  command: any;
  executeInst: any;

  constructor(value: any) {
    this.command = new Command(PostBeat, null, value);
  }

  *execute() {
    this.executeInst = new this.command.executeClass(this.command.value);
    yield this.executeInst.optimisticEventDataUpdate(this.command.value);
  }

  getResult() {
    return this.executeInst.getResult();
  }
}
