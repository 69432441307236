import { select } from 'redux-saga/effects';

import { EVENT_CONST_TYPES } from 'constant/EventConst';

import { convertMsToBpm } from 'util/ChartUtil';

import { Ms, WaveformIndex } from '@type/ecgEventType/eventUnit';
import {
  selectRecordingTime,
  selectSelectionStrip,
  selectTimeEventList,
} from 'redux/duck/testResultDuck';
import { TimeEvent } from '@type/ecgEventType/eventType';

export class optimisticEventDataUpdate {
  static basisBpm = 400;

  // validation
  validateOverHrLimit({
    updateTargetWaveformIndex,
    comparisonWaveformIndexList,
  }: {
    updateTargetWaveformIndex: WaveformIndex;
    comparisonWaveformIndexList: WaveformIndex[];
  }): boolean {
    const basisBpm = optimisticEventDataUpdate.basisBpm;
    const index = comparisonWaveformIndexList.findIndex(
      (v) => v > updateTargetWaveformIndex
    );
    const prevWaveformIndex = comparisonWaveformIndexList[index - 1];
    const nextWaveformIndex = comparisonWaveformIndexList[index];
    const isOverBasisBpmBaseOnPrevWaveformIndex =
      convertMsToBpm((updateTargetWaveformIndex - prevWaveformIndex) * 4) >
      basisBpm;
    const isOverBasisBpmOnNextWaveformIndex =
      convertMsToBpm((nextWaveformIndex - updateTargetWaveformIndex) * 4) >
      basisBpm;

    if (
      isOverBasisBpmBaseOnPrevWaveformIndex ||
      isOverBasisBpmOnNextWaveformIndex
    ) {
      return true;
    }

    return false;
  }

  validateSBeatInAf({
    afList,
    waveformIndex,
    recordingStartMs,
  }: {
    afList: TimeEvent[];
    waveformIndex: WaveformIndex;
    recordingStartMs: Ms;
  }) {
    const selectedEventTimeStamp = recordingStartMs + waveformIndex * 4;
    for (let afInfo of afList) {
      const isWaveformIndexInAfRange =
        selectedEventTimeStamp >= afInfo.onsetMs &&
        selectedEventTimeStamp <= afInfo.terminationMs;
      if (isWaveformIndexInAfRange) {
        return true;
      }
    }
    return false;
  }

  // get redux state
  *getAfList(): Generator<any, TimeEvent[], TimeEvent[]> {
    const EVENT_CONST_TYPE_AF = EVENT_CONST_TYPES.AF;
    const afList: TimeEvent[] = yield select((state) =>
      selectTimeEventList(state, EVENT_CONST_TYPE_AF)
    );
    return afList;
  }
  *getRecordingStartMs(): Generator<any, Ms, { recordingStartMs: Ms }> {
    const { recordingStartMs } = yield select(selectRecordingTime);
    return recordingStartMs;
  }
  *getSelectionStrip(): Generator<
    any,
    { onset: any; termination: any },
    { onset: any; termination: any }
  > {
    return yield select(selectSelectionStrip);
  }
}
