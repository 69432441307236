import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { BEATS_API_MANAGER_MAP } from 'constant/ApiManagerConst';
import { NOTHING_REDUX_ACTION } from 'constant/Const';

import {
  ActionType,
  NewRequestInfo,
  ProcessingTask,
  TaskQueueAction,
  TaskQueueState,
  getTaskQueueTypeByQuery,
  taskQueueType,
} from '@type/optimisticUpdate/taskQueue';
import { CustomAxiosRequestConfig } from '@type/types';

import { uuidManagement } from './testResultDuck';

const CONST_BEATS_API_MANAGER_MAP = BEATS_API_MANAGER_MAP;
const {
  FETCH_BEAT_EVENT: { action: FETCH_BEAT_EVENT },
  //
  POST_TIME_EVENT: { action: POST_TIME_EVENT },
  GET_TIME_EVENTS_LIST: { action: GET_TIME_EVENTS_LIST },
  //
  GET_EVENT_DETAIL: { action: GET_EVENT_DETAIL },
  POST_PROCESS_EDITED_TIME_EVENT: { action: POST_PROCESS_EDITED_TIME_EVENT },
} = taskQueueType;

const initialState: TaskQueueState = {
  requestQueue: [],
  processingTask: null,
  queueValidation: true,
};

// :: Selectors
/** dequeue 를 통해 할당된 beats request 정보, API 요청 중일때만 값이 있음 */
export const selectProcessingTask = (state): any =>
  state.taskQueueReducer.processingTask;
export const selectTaskQueue = (state) => state.taskQueueReducer.requestQueue;
export const selectTaskQueueLength = (state) => selectTaskQueue(state).length;
export const selectValidation = (state) =>
  state.taskQueueReducer.queueValidation;
export const selectFilterTaskOfPostTimeEvent = (state) =>
  selectTaskQueue(state).filter(
    (task) => task.requestStatement.type === POST_TIME_EVENT
  );
export const selectFilterTaskOfGetTimeEvent = (state) =>
  selectTaskQueue(state).filter(
    (task) => task.requestStatement.type === GET_TIME_EVENTS_LIST
  );
export const selectEditTypeTaskQueue = (state) => {
  const editActionTaskQueueMap = getTaskQueueTypeByQuery({
    target: 'actionType',
    value: ActionType.Edit,
  });

  let convertEditActionTaskQueueMap = {};
  for (const key in editActionTaskQueueMap) {
    convertEditActionTaskQueueMap[editActionTaskQueueMap[key].action] =
      editActionTaskQueueMap[key];
  }

  return state.taskQueueReducer.requestQueue.filter((task) => {
    return (
      convertEditActionTaskQueueMap[task.requestStatement.type] ||
      convertEditActionTaskQueueMap[task.requestStatement.requestType]
    );
  });
};

// :: Actions
// Queue Management
const ENQUEUE_REQUEST = 'taskQueue/ENQUEUE_REQUEST';
const DEQUEUE_REQUEST = 'taskQueue/DEQUEUE_REQUEST';
const RELEASE_REQUESTED_INFO = 'taskQueue/RELEASE_REQUESTED_INFO';

// scheduling
const SCHEDULING_GET_EVENT_DETAIL = 'taskQueue/SCHEDULING_GET_EVENT_DETAIL';
// take(CONDITION_MET)를 통해 동기적으로 releaseRequestedInfo()를 호출하기 위한 action
const CONDITION_MET = 'taskQueue/CONDITION_MET';
export function conditionMet() {
  return { type: CONDITION_MET };
}

// :: Reducer
export default function reducer(
  state: TaskQueueState = initialState,
  { type = '', payload }: TaskQueueAction
) {
  switch (type) {
    case ENQUEUE_REQUEST:
      // # enqueueTask: taskQueue에 enqueue되는 task
      const enqueueReqState = payload.newRequestInfo.requestStatement; // taskQueue에 enqueue되는 task의 requestStatement
      const enqueueTaskRequestType = enqueueReqState?.requestType; // FETCH_BEAT_EVENT
      const enqueueTaskType = enqueueReqState?.type as unknown as string; // FETCH_BEAT_EVENT 제외한 나머지
      const enqueueEventType = enqueueReqState?.eventType;

      // # processingTask: 현재 수행 중인 task
      const processingReqState = state.processingTask?.requestStatement;
      const processingTaskType = processingReqState?.type as unknown as string;
      const processingTaskEventType = processingReqState?.eventType;

      // # conditionList: enqueueTask 관련
      const isFetchBeatEvent = enqueueTaskRequestType === FETCH_BEAT_EVENT;
      const isPostTimeEvent = enqueueTaskType === POST_TIME_EVENT;
      const isGetTimeEventsList = enqueueTaskType === GET_TIME_EVENTS_LIST;
      const isGetEventDetail = enqueueTaskType === GET_EVENT_DETAIL;

      // # conditionList: processing 관련
      const isProcessingGetTimeEvent =
        processingTaskType === GET_TIME_EVENTS_LIST;
      const isProcessingGetEventDetail =
        processingTaskType === GET_EVENT_DETAIL;
      const isDifferentEventTypeBtwProcessingAndEnqueue =
        processingTaskEventType !== enqueueEventType;

      /*******************************************************************************************/
      /* # scheduling algorithm 3가지                                                            */
      /* 1. FETCH_BEAT_EVENT case                                                                */
      /*  * scheduling 목적                                                                      */
      /*    - FETCH_BEAT_EVENT 수행 될 때 take queue에 POST_PROCESS_EDITED_TIME_EVENT 있을 경우  */
      /*    - 현재 수행 되는 fetching beat를 통해 받은 beat를 redux state에 업데이트 시키지 않음 */
      /*  * condition                                                                            */
      /*    - enqueueTaskType가 FETCH_BEAT_EVENT                                                 */
      /* 2. POST_TIME_EVENT case                                                                 */
      /*  * scheduling 목적                                                                      */
      /*    - getTimeEvent를 최종적으로 한번만 해주기 위한 과정.                                 */
      /*  * condition                                                                            */
      /*    - enqueueTaskType가 POST_TIME_EVENT 또는 GET_TIME_EVENTS_LIST 이면서                 */
      /*    - processingTaskType이 GET_TIME_EVENT가 아니거나,                                    */
      /*    - processingTaskType이 GET_TIME_EVENT이면서 eventType이 다를 경우                    */
      /* 3.  GET_EVENT_DETAIL case                                                               */
      /*  * scheduling 목적                                                                      */
      /*    - GET_EVENT_DETAIL을 최종적으로 한번만 해주기 위한 과정.                             */
      /*  * condition                                                                            */
      /*    - task queue에 추가되는 task가 GET_EVENT_DETAIL이고                                  */
      /*    - task queue가 진행중인 task가 GET_EVENT_DETAIL이 아닐 경우                          */
      /*******************************************************************************************/

      let requestQueue = [...state.requestQueue, payload.newRequestInfo];
      let queueValidation = true;
      if (isFetchBeatEvent) {
        const isPostProcessEditedTimeEvent = (task: NewRequestInfo) =>
          (task.requestStatement.type as unknown as string) ===
          POST_PROCESS_EDITED_TIME_EVENT;
        const postProcessEditedTimeEventList = state.requestQueue.filter(
          (task) => isPostProcessEditedTimeEvent(task)
        );
        const notPostProcessEditedTimeEventList = state.requestQueue.filter(
          (task) => !isPostProcessEditedTimeEvent(task)
        );
        if (postProcessEditedTimeEventList.length !== 0) {
          queueValidation = false;
          requestQueue = [
            ...[postProcessEditedTimeEventList.at(0)],
            ...notPostProcessEditedTimeEventList,
            payload.newRequestInfo,
            ...postProcessEditedTimeEventList,
          ];
        }
      } else if (
        (isPostTimeEvent || isGetTimeEventsList) &&
        (!isProcessingGetTimeEvent ||
          (isProcessingGetTimeEvent &&
            isDifferentEventTypeBtwProcessingAndEnqueue))
      ) {
        const nonGetTimeEventList = state.requestQueue.filter(
          (task) =>
            task.requestStatement.type !== GET_TIME_EVENTS_LIST ||
            (task.requestStatement.type === GET_TIME_EVENTS_LIST &&
              task.requestStatement.eventType !== enqueueEventType)
        );

        requestQueue = [...nonGetTimeEventList, payload.newRequestInfo];
      } else if (isGetEventDetail && !isProcessingGetEventDetail) {
        const nonGetEventDetail = state.requestQueue.filter(
          (queue) => queue.requestStatement.type !== GET_EVENT_DETAIL
        );

        requestQueue = [...nonGetEventDetail, payload.newRequestInfo];
      }

      return {
        ...state,
        requestQueue,
        queueValidation,
      };
    case DEQUEUE_REQUEST:
      return {
        ...state,
        processingTask: state.requestQueue.at(0) ?? null,
      };
    case RELEASE_REQUESTED_INFO:
      return {
        ...state,
        requestQueue: [...state.requestQueue.slice(1)],
        processingTask: initialState.processingTask,
        queueValidation: initialState.queueValidation,
      };

    // scheduling
    case SCHEDULING_GET_EVENT_DETAIL:
      /*
        'time event type' detail 조회 시 optimistic udpate로 인해 'time event' 추가 이후 바로 조회 시
        time Event Type 없어서 조회 안되어 조회 기능을 getTimeEventList이후 다시 할 수 있도록 하는 로직
      */
      const filterGetEventDetail = state.requestQueue.filter(
        (task) => task.requestStatement.type === GET_EVENT_DETAIL
      );
      return {
        ...state,
        requestQueue: [...state.requestQueue, filterGetEventDetail.at(-1)],
      };
    default:
      return state;
  }
}

// :: Action Creators
// Queue Management
export function enqueueRequest(newRequestInfo: NewRequestInfo) {
  const reqTaskType = newRequestInfo.requestStatement.type as unknown as string;
  // debugging log
  // console.log('reqTaskType: ', reqTaskType, newRequestInfo);

  // todo: jyoon(240630) - [refactor] reqTaskType이 있는 경우와 없는경우 (fetch beat event) 합치기
  // taskQueue.ts > taskQueueType에 사용할 편집 info 작성함
  if (
    reqTaskType === GET_EVENT_DETAIL ||
    reqTaskType === GET_TIME_EVENTS_LIST ||
    reqTaskType === POST_TIME_EVENT ||
    reqTaskType === POST_PROCESS_EDITED_TIME_EVENT
  ) {
    return { type: ENQUEUE_REQUEST, payload: { newRequestInfo } };
  } else {
    // FETCH_BEAT_EVENT 관련로직
    const { waveformIndexes } = newRequestInfo.requestStatement?.reqBody;
    if (waveformIndexes && waveformIndexes.length === 0) {
      console.error('ERROR EDIT BEAT', newRequestInfo);
      return { type: NOTHING_REDUX_ACTION };
    }
    return { type: ENQUEUE_REQUEST, payload: { newRequestInfo } };
  }
}
function dequeueRequest() {
  return { type: DEQUEUE_REQUEST };
}
function releaseRequestedInfo() {
  return { type: RELEASE_REQUESTED_INFO };
}
export function schedulingGetEventDetail() {
  return { type: SCHEDULING_GET_EVENT_DETAIL };
}

// :: Saga Action Handlers

//  pop 되어 API 요청 중인 건이 없다면 새로운 요청을 pop
function* _relayDequeueHandler({ payload = {} } = {}) {
  const processingTask: ProcessingTask = yield select(selectProcessingTask);
  const taskQueueLength: number = yield select(selectTaskQueueLength);
  // debugging log
  // const taskQueue = yield select(selectTaskQueue);
  // console.log(
  //   '### taskQueueDuck.ts > +++ > _relayDequeueHandler > processingTask, taskQueue: ',
  //   processingTask,
  //   taskQueue
  // );

  if (
    isPendingApiResponse(processingTask) ||
    isEmptyTaskQueue(taskQueueLength)
  ) {
    return;
  }

  // pop & execute task in taskQueue
  yield put(dequeueRequest());
}

//  pop 된 API 요청 건을 실행, 완료 된 후 해당 요청(processingTask)은 state 에서 해제
function* _dequeueHandler({ payload = {} } = {}) {
  const processingTask: ProcessingTask = yield select(selectProcessingTask);
  // debugging log
  // const taskQueue = yield select(selectTaskQueue);
  // console.log(
  //   '### taskQueueDuck.ts > --- > _dequeueHandler > processingTask, taskQueue: ',
  //   processingTask,
  //   taskQueue
  // );
  if (!processingTask) return;

  const {
    requestStatement: { requestType, ecgTestId, reqBody },
    succeedCallback,
    failedCallback,
  } = processingTask;
  // taskQueueType가 undefined면 beat편집 로직 임
  const taskQueueType = processingTask.requestStatement
    .type as unknown as string;

  try {
    const isPostTimeEvent = taskQueueType === POST_TIME_EVENT;
    const isGetEventDetail = taskQueueType === GET_EVENT_DETAIL;
    const isGetTimeEventsList = taskQueueType === GET_TIME_EVENTS_LIST;
    const isPostProcessEditedTimeEvent =
      taskQueueType === POST_PROCESS_EDITED_TIME_EVENT;
    const isEditBeatEvent = requestType !== undefined;

    if (isPostTimeEvent) {
      // timeEvent Edit API 요청
      const uuid = uuidV4();
      uuidManagement[processingTask.requestStatement.eventType] = uuid;
      axios.interceptors.request.use(async function (
        config: CustomAxiosRequestConfig
      ) {
        if (
          config &&
          config.url &&
          config.url.includes('api/time-events') &&
          config.method === 'post'
        ) {
          if (config.traceId) return config;
          config.traceId = uuid;
        }
        return config;
      });
      const responseData: any[] = yield all(processingTask.calls);
      yield put(releaseRequestedInfo());
      yield succeedCallback && call(succeedCallback, { responseData });
    } else if (isGetEventDetail || isGetTimeEventsList) {
      yield processingTask.getAction &&
        put(
          processingTask.getAction({
            releaseRequestedInfoAction: releaseRequestedInfo,
          })
        );
      // yield take(CONDITION_MET);
      // yield put(releaseRequestedInfo());
    } else if (isPostProcessEditedTimeEvent) {
      // get beats list API 요청
      yield call(processingTask.requestStatement.postProcessEditedTimeEvent, {
        params: processingTask.requestStatement,
        releaseRequestedInfoAction: releaseRequestedInfo,
      });
    } else if (isEditBeatEvent) {
      // beat Edit API 요청
      const editBeatApi: any = getEditBeatApi(requestType);
      const responseBody = yield call(editBeatApi, ecgTestId, reqBody);
      yield put(releaseRequestedInfo());
      yield succeedCallback && call(succeedCallback, responseBody);
    }
  } catch (error) {
    yield put(releaseRequestedInfo());
    yield failedCallback && call(failedCallback, error);
  }
}

// :: Saga
export function* saga() {
  yield takeEvery(ENQUEUE_REQUEST, _relayDequeueHandler);
  yield takeEvery(DEQUEUE_REQUEST, _dequeueHandler);
  yield takeEvery(RELEASE_REQUESTED_INFO, _relayDequeueHandler);
}

function getEditBeatApi(type: string = '') {
  return CONST_BEATS_API_MANAGER_MAP[type];
}
function isPendingApiResponse(processingTask: ProcessingTask) {
  return processingTask ? true : false;
}
function isEmptyTaskQueue(queueLength: number) {
  return queueLength === 0;
}
