/*********************************/
/*  # index                      */
/*                               */
/*  enum EventType                */
/*  enum BeatType                */
/*  enum EctopicType             */
/*  enum TimeEventType           */
/*                               */
/*  enum EventSection            */
/*  enum ReportType              */
/*  enum EventConstTypes         */
/*  enum ShapeReviewEventType    */
/*                               */
/*  //                           */
/*  type TimeEventConstTypes     */
/*  type BeatEventConstTypes     */
/*  type ShapeReviewEventSection */
/*                               */
/*********************************/

export enum EventType {
  BeatType = 'BeatType',
  TimeEventType = 'TimeEventType',
  // EctopicType,
}

export enum BeatType {
  NORMAL = 0,
  APC = 1,
  VPC = 2,
  NOISE = 3,
}

// const baseEventType = {
//   BEAT_EVENT_TYPE: {
//     NORMAL: 0,
//     APC: 1,
//     VPC: 2,
//     NOISE: 3,
//   },
//   TIME_EVENT_TYPE: {},
// } as Const;

export enum EctopicType {
  ISOLATE = 'ISOLATE',
  COUPLET = 'COUPLET',
  RUN = 'RUN',
}

export enum TimeEventType {
  AF = 'AF',
  PAUSE = 'PAUSE',
  OTHERS = 'OTHERS',
  LEAD_OFF = 'LEAD_OFF',
  AV_BLOCK = 'AV_BLOCK',
  AVB_2 = 'AVB-GROUP-2',
  AVB_3 = 'AVB-3',
}

// todo: jyoon(240625) - [refactor] enum TimeEventType과 중복되는 값이 있어서 수정 필요
// * 기존에 사용하던 EventConst.js > EVENT_SECTION에서는 사이드패널의 이벤트 리스트를 정의하거나, qty등을 불러올떄 사용
export enum EventSection {
  PATIENT = 'PTE',
  //
  AF = 'AF', // TIME_EVENT_TYPE.AF
  PAUSE = 'PAUSE', // TIME_EVENT_TYPE.PAUSE
  AVB_2 = 'AVB-GROUP-2', // TIME_EVENT_TYPE.AVB_2
  AVB_3 = 'AVB-3', // TIME_EVENT_TYPE.AVB_3
  OTHERS = 'OTHERS', // TIME_EVENT_TYPE.OTHERS
  //
  VT = 'VT',
  ISO_VPC = 'IsoVPC',
  COUPLET_VPC = 'CoupletVPC',
  BIGEMINY_VPC = 'BigeminyVPC',
  TRIGEMINY_VPC = 'TrigeminyVPC',
  Quadrigeminy_VPC = 'QuadrigeminyVPC',
  //
  SVT = 'SVT',
  ISO_APC = 'IsoAPC',
  COUPLET_APC = 'CoupletAPC',
  BIGEMINY_APC = 'BigeminyAPC',
  TRIGEMINY_APC = 'TrigeminyAPC',
  QUADRIGEMINY_APC = 'QuadrigeminyAPC',
}

export enum ReportType {
  SUMMARY = 'SUMMARY',
  DETAIL = 'DETAIL',
  ADDITIONAL = 'ADDITIONAL',
}

// export const EventConstTypes = {
//   // time-event
//   AF: `EVENT-TYPE-${timeEventType.AF}`,
//   PAUSE: `EVENT-TYPE-${timeEventType.PAUSE}`,
//   AV_BLOCK: `EVENT-TYPE-${timeEventType.AV_BLOCK}`, // AV
//   AVB_2: `EVENT-TYPE-${timeEventType.AVB_2}`, // 2nd AVB
//   AVB_3: `EVENT-TYPE-${timeEventType.AVB_3}`, // 3rd AVB
//   OTHERS: `EVENT-TYPE-${timeEventType.OTHERS}`,
//   //
//   ISO_VPC: `EVENT-TYPE-${EctopicType.ISOLATE}-${BeatType.VPC}`,
//   COUPLET_VPC: `EVENT-TYPE-${EctopicType.COUPLET}-${BeatType.VPC}`,
//   VE: `EVENT-TYPE-X-${EctopicType.RUN}-${BeatType.VPC}`,
//   VT: `EVENT-TYPE-${EctopicType.RUN}-${BeatType.VPC}`,
//   ISO_APC: `EVENT-TYPE-${EctopicType.ISOLATE}-${BeatType.APC}`,
//   COUPLET_APC: `EVENT-TYPE-${EctopicType.COUPLET}-${BeatType.APC}`,
//   SVE: `EVENT-TYPE-X-${EctopicType.RUN}-${BeatType.APC}`, // RUN이 아닌 경우
//   SVT: `EVENT-TYPE-${EctopicType.RUN}-${BeatType.APC}`,
//   //
//   PATIENT: 'EVENT-TYPE-PATIENT',
//   //
//   NOTABLE: `EVENT-TYPE-${ReportType.SUMMARY}`,
//   ADDITIONAL: `EVENT-TYPE-${ReportType.ADDITIONAL}`,
//   //
//   NORMAL: `EVENT-TYPE-${BeatType.NORMAL}`,
//   NOISE: `EVENT-TYPE-${BeatType.NOISE}`,
//   LEAD_OFF: `EVENT-TYPE-${timeEventType.LEAD_OFF}`,
// } as const;
const generateEventConstType = (
  type: TimeEventType | EctopicType | ReportType | BeatType,
  subType?: BeatType
) => `EVENT-TYPE-${type}${subType !== undefined ? `-${subType}` : ''}`;

// todo: jyoon(240625) - [refactor] EventConst.js > EVENT_CONST_TYPES 사용하는 것 아래것으로 변경
export const EventConstTypes = {
  AF: generateEventConstType(TimeEventType.AF),
  PAUSE: generateEventConstType(TimeEventType.PAUSE),
  AV_BLOCK: generateEventConstType(TimeEventType.AV_BLOCK),
  AVB_2: generateEventConstType(TimeEventType.AVB_2),
  AVB_3: generateEventConstType(TimeEventType.AVB_3),
  OTHERS: generateEventConstType(TimeEventType.OTHERS),
  ISO_APC: generateEventConstType(EctopicType.ISOLATE, BeatType.APC),
  ISO_VPC: generateEventConstType(EctopicType.ISOLATE, BeatType.VPC),
  COUPLET_APC: generateEventConstType(EctopicType.COUPLET, BeatType.APC),
  COUPLET_VPC: generateEventConstType(EctopicType.COUPLET, BeatType.VPC),
  VT: generateEventConstType(EctopicType.RUN, BeatType.VPC),
  SVE: generateEventConstType('X' as EctopicType, BeatType.APC),
  SVT: generateEventConstType(EctopicType.RUN, BeatType.APC),
  VE: generateEventConstType('X' as EctopicType, BeatType.VPC),
  PATIENT: 'EVENT-TYPE-PATIENT',
  NOTABLE: generateEventConstType(ReportType.SUMMARY),
  ADDITIONAL: generateEventConstType(ReportType.ADDITIONAL),
  NORMAL: generateEventConstType(BeatType.NORMAL),
  NOISE: generateEventConstType(BeatType.NOISE),
  LEAD_OFF: generateEventConstType(TimeEventType.LEAD_OFF),
} as const;

// shape-review
export const ShapeReviewEventType = {
  ISO_VPC: EventConstTypes.ISO_VPC,
  COUPLET_VPC: EventConstTypes.COUPLET_VPC,
  ISO_APC: EventConstTypes.ISO_APC,
  COUPLET_APC: EventConstTypes.COUPLET_APC,
} as const;

// To facilitate type checking, you can also define a type based on the const object
export type ShapeReviewEventType =
  typeof ShapeReviewEventType[keyof typeof ShapeReviewEventType];

// type
export type TimeEventConstTypes =
  | typeof EventConstTypes.AF
  | typeof EventConstTypes.PAUSE
  | typeof EventConstTypes.AV_BLOCK
  | typeof EventConstTypes.AVB_2
  | typeof EventConstTypes.AVB_3
  | typeof EventConstTypes.OTHERS;

export type BeatEventConstTypes =
  | typeof EventConstTypes.ISO_APC
  | typeof EventConstTypes.ISO_VPC
  | typeof EventConstTypes.COUPLET_APC
  | typeof EventConstTypes.COUPLET_VPC
  | typeof EventConstTypes.VT
  | typeof EventConstTypes.SVT;

export type ShapeReviewEventSection =
  | EventSection.ISO_APC
  | EventSection.COUPLET_APC
  | EventSection.ISO_VPC
  | EventSection.COUPLET_VPC;
