import { select } from 'redux-saga/effects';

import { EVENT_CONST_TYPES } from 'constant/EventConst';

import {
  OptimisticEventDataUpdateForBeats,
  hrValue,
} from '@type/optimisticUpdate/type';
import { BeatType } from '@type/ecgEventType/baseEventType';
import {
  selectRecordingTime,
  selectTimeEventList,
} from 'redux/duck/testResultDuck';
import { TimeEvent } from '@type/ecgEventType/eventType';

import { PatchBeat, Validation } from './eventReview_PatchBeat';

import {
  ReqBody,
  UpdateReqOptionProps,
} from '../optimisticEventDataUpdateForBeatEvent';
import { Command } from '../../eventUpdateCmdPattern';

export class PatchBeatByRangeList extends PatchBeat {
  result: any;
  reqParam: any;

  constructor(reqParam: any) {
    super();
    this.reqParam = reqParam;
  }

  *optimisticEventDataUpdate({
    updateReqOption,
    filterBeatsNEctopicList,
  }: UpdateReqOptionProps) {
    let result: OptimisticEventDataUpdateForBeats = {
      hr: [],
      beatType: [],
      waveformIndex: [],
    };

    const { reqBody } = updateReqOption;
    const mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats =
      this.mergeBeatsNEctopicList(filterBeatsNEctopicList);
    const copyMergedBeatsNEctopicList = this.rfdcClone(mergedBeatsNEctopicList);

    // STEP1: beatUpdate
    result = this.updateLogic({ reqBody, mergedBeatsNEctopicList });
    // STEP2: validation
    result = yield this._validation({
      reqBody,
      updateReqOption,
      mergedBeatsNEctopicList: result,
      copyMergedBeatsNEctopicList,
    });

    this.result = this.setOptimisticEventDataUpdateResult({
      isValidationSuccessful: true,
      result,
    });
  }

  updateLogic({
    reqBody,
    mergedBeatsNEctopicList,
  }: {
    reqBody: ReqBody;
    mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats;
  }): OptimisticEventDataUpdateForBeats {
    const {
      hr: hrList,
      beatType: beatTypeList,
      waveformIndex: waveformIndexList,
    } = mergedBeatsNEctopicList;

    const waveformIndexListLength = waveformIndexList.length;
    for (let idx = 0; idx < waveformIndexListLength; idx++) {
      const updateTargetWaveformIndex = waveformIndexList[idx];

      if (
        reqBody.leftWaveformIndex <= updateTargetWaveformIndex &&
        reqBody.rightWaveformIndex >= updateTargetWaveformIndex
      ) {
        // Noise(Q비트)를 다른 비트로 변경했을때 Q비트였던 비트의 다음 비트(updateTargetWaveformIndexIndex + 1)는 hr값을 가져야한다.
        if (beatTypeList[idx] === BeatType.NOISE) {
          hrList[idx + 1] = hrValue.optimisticEventDataUpdated;
        }

        // 요청한 비트 타입으로 변경
        beatTypeList[idx] = reqBody.beatType;

        // 비트를 Noise(Q비트)로 변경 시
        if (reqBody.beatType === BeatType.NOISE) {
          hrList[idx] = hrValue.optimisticEventDataUpdated;
        }
      }
    }

    /**
     * √: beat 위치
     *
     *                     rightWaveformIndex
     *  leftWaveformIndex  ⎸
     *         ↓          ↓
     * ---√---√---√---√---√---√---√---√---√---√---
     *                        ↑
     *                        rightNextWaveformIndexOfRightWaveformIndex
     */
    if (reqBody.beatType === BeatType.NOISE) {
      const rightNextWaveformIndexOfRightWaveformIndex =
        waveformIndexList.findIndex(
          (waveformIndex) => waveformIndex >= reqBody.rightWaveformIndex
        );

      hrList[rightNextWaveformIndexOfRightWaveformIndex] =
        hrValue.optimisticEventDataUpdated;
    }

    return mergedBeatsNEctopicList;
  }

  *_validation({
    reqBody,
    updateReqOption,
    mergedBeatsNEctopicList,
    copyMergedBeatsNEctopicList,
  }: Validation): any /* : Generator<IBeatsResponseResult> */ {
    const {
      hr: hrList,
      beatType: beatTypeList,
      waveformIndex: waveformIndexList,
    } = mergedBeatsNEctopicList;

    if (reqBody.beatType === BeatType.APC) {
      const EVENT_CONST_TYPE_AF = EVENT_CONST_TYPES.AF;
      const { recordingStartMs } = yield select(selectRecordingTime);
      const afList: TimeEvent[] = yield select((state) =>
        selectTimeEventList(state, EVENT_CONST_TYPE_AF)
      );

      waveformIndexList.forEach((updateTargetWaveformIndex, idx) => {
        if (
          reqBody.leftWaveformIndex <= updateTargetWaveformIndex &&
          reqBody.rightWaveformIndex >= updateTargetWaveformIndex
        ) {
          const selectedEventTimeStamp =
            recordingStartMs + updateTargetWaveformIndex * 4;
          const isWithinAfEvent = afList.some(
            (afInfo) =>
              selectedEventTimeStamp >= afInfo.onsetMs &&
              selectedEventTimeStamp <= afInfo.terminationMs
          );

          if (isWithinAfEvent) {
            hrList[idx] = null;
            beatTypeList[idx] = copyMergedBeatsNEctopicList.beatType[idx];
            waveformIndexList[idx] = updateTargetWaveformIndex;
          }
        }
      });
    }

    return yield this.validation({
      reqBody,
      updateReqOption,
      mergedBeatsNEctopicList,
      copyMergedBeatsNEctopicList,
    });
  }

  getResult() {
    return this.result;
  }
}

// ### COMMAND 역할
export class PatchBeatByRangeListCommand {
  command: any;
  executeInst: any;

  constructor(value: any) {
    this.command = new Command(PatchBeatByRangeList, null, value);
  }

  *execute() {
    this.executeInst = new this.command.executeClass(this.command.value);
    yield this.executeInst.optimisticEventDataUpdate(this.command.value);
  }

  getResult() {
    return this.executeInst.getResult();
  }
}
