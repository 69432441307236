import rfdc from 'rfdc';

import {
  TEN_SEC_SCRIPT_DETAIL,
  ECG_CHART_UNIT,
  TEN_SEC_STRIP_EDIT,
} from 'constant/ChartEditConst';
import Const from 'constant/Const';

import { getEventInfo } from '../EventConstUtil';

const THIRTY_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX;
const rfdcClone = rfdc();

/**
 * withBeat 값이 True 인 Raw API 응답값을 LongTermChart, ShortTermChart 에서 필요한 형태로 변환합니다.
 *
 * @param {Array<{onsetMs, terminationMs, avgHr, minHr, maxHr, ecgData}>} ecgRawList raw API 로 응답된 구간 데이터
 * @returns {TransformedRawType[]}
 */
export const transformRawList = (ecgRawList) => {
  const result = ecgRawList.map((raw) => {
    raw.createAt = new Date().getTime();
    raw.baselineWaveformIndex = raw.onsetWaveformIndex;
    return raw;
  });

  return result;
};

/**
 * 부정맥 이벤트 API 조회 결과를 비즈니스 로직에서 활용할 형태로 변환합니다.
 *
 * @param {Array} timeEventGroups
 * @returns {TransformedEventType[]}
 */
export const transformTimeEvents = (timeEventGroups) => {
  let result = [];

  // Time Event 정보 가공
  for (const iIndex in timeEventGroups) {
    const parsingTimeEventGroup = timeEventGroups[iIndex].map(
      (value, jIndex) => {
        return {
          createAt: new Date().getTime(),
          durationMs: value.durationMs,
          onsetMs: value.onsetMs,
          terminationMs: value.terminationMs,
          type: getEventInfo({ timeEventType: value.eventType }).findOne()
            ?.type,
          timeEventId: value.id,
          onsetRPeakIndex: null,
          position: parseInt(jIndex) + 1,
        };
      }
    );

    result.push(...parsingTimeEventGroup);
  }

  return result;
};

export const mergeLeadOffInfo = (_prevList, _newList) => {
  const prevList = rfdcClone(_prevList);
  const newList = rfdcClone(_newList);

  if (prevList.length === 0) return newList;
  if (newList.length === 0) return prevList;

  return [
    ...prevList,
    ...newList
      .map((newInfo) => {
        const prevMatchedInfo = prevList.find(
          (prevInfo) =>
            !(
              prevInfo.terminationMs < newInfo.onsetMs ||
              newInfo.terminationMs < prevInfo.onsetMs
            )
        );
        if (prevMatchedInfo) {
          prevMatchedInfo.onsetMs = Math.min(
            prevMatchedInfo.onsetMs,
            newInfo.onsetMs
          );
          prevMatchedInfo.terminationMs = Math.max(
            prevMatchedInfo.terminationMs,
            newInfo.terminationMs
          );
          return false;
        } else {
          return newInfo;
        }
      })
      .filter((v) => !!v),
  ];
};

export const getInitRepresentativeStripInfo = (reportEventDetailData) => {
  const {
    reportSection,
    representativeOnsetIndex,
    representativeTerminationIndex,
  } = reportEventDetailData ?? {};

  if (!representativeOnsetIndex || !representativeTerminationIndex) {
    return {
      selectedMs: null,
      representativeOnsetIndex: null,
      representativeTerminationIndex: null,
    };
  } else {
    const interHalfWaveformLength = Math.floor(
      (representativeTerminationIndex - representativeOnsetIndex) / 2
    );
    return {
      selectedMs: !interHalfWaveformLength
        ? null
        : representativeOnsetIndex + interHalfWaveformLength,
      representativeOnsetIndex,
      representativeTerminationIndex,
      reportSection,
    };
  }
};

/**
 * # 해당 fn이 수행 되는 30s, 10s strip
 *   * 30s strip, 10s strip에서 편집할때 수행됩니다.
 *
 * # strip 기준 별로 globalOnsetWaveformIndex, globalTerminationWaveformIndex 값 설명
 *   * 30s strip에서 편집될때
 *     - SelectionStrip onset, termination 구간
 *   * 10s strip에서 편집될때
 *     - 10s strip onset, termination 구간
 * @param {*} globalOnsetWaveformIndex
 * @param {*} globalTerminationWaveformIndex
 * @returns {Object} searchOnsetRequest, searchTerminationRequest
 */
export const getSearchBeatsNEctopicListRangeAfterUpdateEvent = (
  globalOnsetWaveformIndex,
  globalTerminationWaveformIndex
) => {
  let result = {
    searchOnsetRequest: undefined,
    searchTerminationRequest: undefined,
  };
  const xAxisMax = THIRTY_SEC_WAVEFORM_LENGTH;
  const halfTenSecWaveformIdx = ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX;
  const localOnsetWaveformIndex = globalOnsetWaveformIndex % xAxisMax;
  const localTerminationWaveformIndex =
    globalTerminationWaveformIndex % xAxisMax;
  const representativeOnsetWaveformIndex =
    globalOnsetWaveformIndex - localOnsetWaveformIndex;

  // event review > 10s strip에서 비트 편집할때
  // 10s strip onset 지점이 30초 strip에 10초 보다 작은 구간일때와 아닌 구간일때
  // 다른 representativeOnsetWaveformIndexOnCondition 값을 갖습니다.
  // const representativeOnsetWaveformIndexOnCondition =
  //   representativeOnsetWaveformIndex;
  const representativeOnsetWaveformIndexOnCondition =
    localOnsetWaveformIndex <= halfTenSecWaveformIdx
      ? representativeOnsetWaveformIndex - xAxisMax
      : representativeOnsetWaveformIndex;
  const representativeTerminationWaveformIndex =
    globalTerminationWaveformIndex - localTerminationWaveformIndex;

  result = {
    searchOnsetRequest: representativeOnsetWaveformIndex,
    searchTerminationRequest: representativeTerminationWaveformIndex + xAxisMax,
  };

  return result;
};

/*************************************/
/*  # 10s strip detail 관련 로직     */
/*    - _getFilterBeatsNEctopicList  */
/*        : detail beat info         */
/*    - _getTenSecStripInfo          */
/*        : get list                 */
/*    - _getHrAvg                    */
/*        : calc avg hr              */
/*************************************/
// 10s strip detail - detail beat info
export const _getFilterBeatsNEctopicList = (
  beatsNEctopicList,
  startTenSecStripWaveformIdx,
  endTenSecStripWaveformIdx
) => {
  let filterBeatsNEctopicList = [];

  for (let waveformIndex in beatsNEctopicList) {
    const parseWaveformIndex = parseInt(waveformIndex);
    if (
      !(
        parseWaveformIndex + 7500 < startTenSecStripWaveformIdx ||
        parseWaveformIndex > endTenSecStripWaveformIdx
      )
    ) {
      filterBeatsNEctopicList.push(beatsNEctopicList[parseWaveformIndex]);
    }
  }

  return filterBeatsNEctopicList;
};
// 10s strip detail - get list
export const _getTenSecStripInfo = (
  filterBeatsNEctopicList,
  startTenSecStripWaveformIdx,
  endTenSecStripWaveformIdx,
  target
) => {
  let filterTargetData;

  let startBeatWaveformIndex, endBeatWaveformIndex;
  if (filterBeatsNEctopicList.length === 1) {
    startBeatWaveformIndex =
      filterBeatsNEctopicList[0].beats.waveformIndex.findIndex(
        (v) => v > startTenSecStripWaveformIdx
      );

    endBeatWaveformIndex =
      filterBeatsNEctopicList[0].beats.waveformIndex.findLastIndex(
        (v) => v < endTenSecStripWaveformIdx
      );

    filterTargetData = filterBeatsNEctopicList[0].beats[target].slice(
      startBeatWaveformIndex,
      endBeatWaveformIndex + 1
    );
  } else if (filterBeatsNEctopicList.length === 2) {
    startBeatWaveformIndex =
      filterBeatsNEctopicList[0].beats.waveformIndex.findIndex(
        (v) => v > startTenSecStripWaveformIdx
      );

    endBeatWaveformIndex =
      filterBeatsNEctopicList[1].beats.waveformIndex.findLastIndex(
        (v) => v < endTenSecStripWaveformIdx
      );

    const filterTargetData1 = filterBeatsNEctopicList[0].beats[target].slice(
      startBeatWaveformIndex
    );
    const filterTargetData2 = filterBeatsNEctopicList[1].beats[target].slice(
      0,
      endBeatWaveformIndex + 1
    );

    filterTargetData = [...filterTargetData1, ...filterTargetData2];
  } else {
    filterTargetData = [];
  }

  if (target === TEN_SEC_SCRIPT_DETAIL.WAVEFORM_INDEX) {
    filterTargetData = filterTargetData.map(
      (v) => v - startTenSecStripWaveformIdx
    );
  }

  return filterTargetData;
};
// 10s strip detail - calc avg hr
export const _getHrAvg = (beatsNEctopicList) => {
  let result, sumHr;

  sumHr = beatsNEctopicList.reduce((acc, cur) => acc + cur, 0);
  result =
    Math.floor((sumHr / beatsNEctopicList.filter(Boolean).length) * 10) / 10;

  return result;
};

/**
 * 10초 strip의 beat의 r-peak에 render할 button 정보
 * @param {number[]} beatTypeList
 * @param {number[]} beatWaveformIndexList
 * @param {number} centerEventOfCurrentPosition
 * @returns {_getBeatLabelButtonData[]}
 */
export const _getBeatLabelButtonDataList = ({
  beatTypeList,
  beatWaveformIndexList,
  centerEventOfCurrentPosition,
}) => {
  let beatLabelButtonDataList = [];
  const beatType = TEN_SEC_STRIP_EDIT.BEAT_TYPE;
  const beatColorType = TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE;

  for (let index in beatWaveformIndexList) {
    beatLabelButtonDataList.push({
      xAxisPoint: beatWaveformIndexList[index],
      isSelected: !!centerEventOfCurrentPosition
        ? beatWaveformIndexList[index] === centerEventOfCurrentPosition
        : false,
      beatType: beatTypeList[index],
      title: beatType[beatTypeList[index]],
      color: beatColorType[beatTypeList[index]],
      isEventReview: '',
    });
  }

  return beatLabelButtonDataList;
};

/**
 * onsetIndex, terminationIndex이 주어졌을때 중간 waveformIndex가 30초 차트에서 waveformIndex가 몇인지 구하는 함수
 *
 * @param {*} onsetIndex
 * @param {*} terminationIndex
 * @returns 값의 범위는 0보다 크거나 같고 7499보다 작거나 같습니다.
 */
export const getLocalCenterWaveformIndex = (onsetIndex, terminationIndex) => {
  const thirtySecWaveformIdx = ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX;
  const rst =
    (onsetIndex + Math.round((terminationIndex - onsetIndex) / 2)) %
    thirtySecWaveformIdx;

  return rst;
};

/**
 * onsetIndex, terminationIndex이 주어졌을때 중간 waveformIndex가 위치한 30초 차트의 첫번째 waveformIndex가 몇인지 구하는 함수
 *
 * @param {*} onsetIndex
 * @param {*} terminationIndex
 * @returns 7500(30초에 대한 waveformIndex)로 나눴을때 나머지가 0인 숫자입니다.
 */
export const getRepresentativeCenterWaveformIndex = (
  onsetIndex,
  terminationIndex
) => {
  const thirtySecWaveformIdx = ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX;
  const rst =
    Math.floor((onsetIndex + terminationIndex) / 2 / thirtySecWaveformIdx) *
    thirtySecWaveformIdx;

  return rst;
};

export const getUpdatedSelectedEventList = ({
  prevSelectedEventList,
  newSelectedEvent,
  isSelectedEventUpdate,
}) => {
  if (prevSelectedEventList.length !== 1 || !isSelectedEventUpdate) {
    return prevSelectedEventList;
  }

  const prevSelectedValue = prevSelectedEventList[0];
  return [
    {
      type: prevSelectedValue.type,
      position: newSelectedEvent.position ?? prevSelectedValue.position,
      timeEventId:
        newSelectedEvent.timeEventId ?? prevSelectedValue.timeEventId,
      waveformIndex:
        newSelectedEvent.waveformIndex ?? prevSelectedValue.waveformIndex,
    },
  ];
};

/**
 * 구간의 가운데 지점과 구간의 길이로 Onset, Termination 지점을 반환
 * @param {number} centerWaveformIndex 구간의 가운데 Waveform Index
 * @param {number} halfLength 구간의 길이 절반
 * @param {number} recordingEndWaveformIndex 검사의 마지막 Waveform Index
 */
export const getOnsetTerminationByCenter = (
  centerWaveformIndex,
  halfLength,
  recordingEndWaveformIndex
) => {
  let modifiedCenterWaveformIndex = centerWaveformIndex;
  let onsetWaveformIndex = centerWaveformIndex - halfLength;
  let terminationWaveformIndex = centerWaveformIndex + halfLength;

  if (onsetWaveformIndex < 0) {
    onsetWaveformIndex = 0;
    modifiedCenterWaveformIndex = halfLength;
    terminationWaveformIndex = halfLength * 2;
  }
  if (recordingEndWaveformIndex < terminationWaveformIndex) {
    onsetWaveformIndex = recordingEndWaveformIndex - halfLength * 2;
    modifiedCenterWaveformIndex = recordingEndWaveformIndex - halfLength;
    terminationWaveformIndex = recordingEndWaveformIndex;
  }
  return {
    modifiedCenterWaveformIndex,
    onsetWaveformIndex,
    terminationWaveformIndex,
  };
};

export const getIsRawDataOnly = ({
  isRawDataOnly,
  isRawDataReady,
  ecgTestStatus,
  cloudStatus,
}) => {
  if (!isRawDataReady) return false;
  if (cloudStatus >= Const.CLOUD_ANALYSIS_STATUS.UNDER_PARTNER) return false;

  return isRawDataOnly || ecgTestStatus === Const.ECG_TEST_STATUS.UNDER_CLOUD;
};

/**
 * @typedef {Object} _getBeatLabelButtonData
 * @property {number} beatType
 * @property {string} color
 * @property {string} isEventReview
 * @property {boolean} isSelected
 * @property {string} title
 * @property {number} xAxisPoint
 */

/**
 * @typedef {Object} TransformedEventType Time Event 와 Ectopic 정보를 비즈니스 로직에서 참조하기위해 가공된 정보구조
 * @property {Timestamp} createAt 정보 가공 시점
 * @property {Timestamp | undefined} onsetMs 이벤트 시작 시간
 * @property {number | undefined} onsetWaveformIndex 이벤트 시작 waveform 위치
 * @property {Timestamp | undefined} terminationMs 이벤트 종료 시간
 * @property {number | undefined} terminationWaveformIndex 이벤트 종료 waveform 위치
 * @property {string} type 이벤트 구분값, EVENT_CONST_TYPES 참조
 * @property {number | null} timeEventId 이벤트가 Time Event 정보라면, API 조회를 위한 식별값
 * @property {number | null} onsetRPeakIndex 이벤트가 Ectopic 정보라면, API 조회를 위한 식별값
 * @property {number} position 동일 이벤트 그룹 내 순번(type 에 따라 고유 기준으로 정렬된 이벤트 정보의 순번), 1부터 시작
 */
